import { forwardRef, useEffect, useState } from "react"

import { ErrorMessage } from "./error-message"
import { ReactComponent as ViewOff } from "../assets/view-off.svg"
import { ReactComponent as ViewOn } from "../assets/view-on.svg"
import { cx } from "shared/helpers"

export const PasswordField = forwardRef((props, ref) => {
  const { value, errorMessage, onChange = () => {}, ...rest } = props

  const [viewOn, setViewOn] = useState(false)
  const toggleView = () => setViewOn(on => !on)

  const [rValue, setRValue] = useState(value)

  const handleChange = ev => {
    setRValue(ev.target.value)
    onChange(ev)
  }

  const hasValue = Boolean(rValue)

  useEffect(() => {
    setRValue(value)
  }, [value])

  return (
    <>
      <div className="group relative hover:bg-gray-900 dark:hover:bg-white  dark:hover:text-gray-900 hover:text-white">
        <input
          {...rest}
          ref={ref}
          type={viewOn ? "text" : "password"}
          className={cx(
            "h2 h-[60px] w-full border-2 border-gray-900 px-[20px] outline-none placeholder:text-gray-900 placeholder:opacity-30 group-hover:placeholder:text-white dark:border-white dark:placeholder:text-white dark:group-hover:placeholder:text-gray-900 md:h-[90px] md:px-[30px] 2xl:h-[100px]",
            {
              "bg-gray-900": hasValue,
              "dark:bg-white": hasValue,
              "text-white": hasValue,
              "dark:text-gray-900": hasValue,
              "bg-transparent": !hasValue
            }
          )}
          value={value}
          onChange={handleChange}
        />
        <button
          tabIndex="-1"
          className={cx("absolute top-0 right-[20px] bottom-0 md:right-[30px]", {
            "text-white": hasValue,
            "dark:text-gray-900": hasValue
          })}
          onClick={toggleView}
        >
          {!viewOn && <ViewOff className="block h-[20px] w-[20px] md:h-[30px] md:w-[30px]" />}
          {viewOn && <ViewOn className="block h-[20px] w-[20px] md:h-[30px] md:w-[30px]" />}
        </button>
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  )
})

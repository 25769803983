import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"

export function SuggestSignIn() {
  return (
    <div className="flex items-end justify-between md:block">
      <div className="h2">мы уже партнеры?</div>
      <div>
        <HoverTrigger className="inline-flex">
          <Underline reverse>
            <Link className="btn no-underline" to="/auth/sign-in">
              вход
            </Link>
          </Underline>
        </HoverTrigger>
      </div>
    </div>
  )
}

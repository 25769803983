import { NewsCard } from "./news-card"

export function NewsList({ news }) {
  return (
    <div className="grid gap-[20px] md:gap-[40px]">
      {news.map(news => (
        <div key={news.id} className="cursor-pointer">
          <NewsCard id={news.id} datetime={news.publishedAt} picture={news.pictureUrl} name={news.name} />
        </div>
      ))}
    </div>
  )
}

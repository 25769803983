import { motion } from "framer-motion"

export function ProgressBar() {
  return (
    <motion.div className="fixed top-0 left-0 right-0 h-1 z-30 overflow-hidden">
      <motion.div
        animate="animate"
        exit="exit"
        variants={containerMotion}
        className="absolute top-0 left-0 right-0 h-1 z-30 flex justify-center overflow-hidden"
      >
        <motion.div
          animate="animate"
          exit="exit"
          variants={progressMotion}
          className="bg-gray-900 dark:bg-white h-1"
        />
      </motion.div>
    </motion.div>
  )
}

const containerMotion = {
  exit: {
    x: "50%",
    transition: {
      duration: 2.5
    }
  },
  animate: {
    x: ["-45%", "45%"],
    transition: {
      repeat: Infinity,
      repeatType: "reverse",
      duration: 2.5
    }
  }
}

const progressMotion = {
  exit: {
    width: 0,
    transition: {
      duration: 2,
      delay: 0.5
    }
  },
  animate: {
    width: ["20%", "90%"],
    transition: {
      repeat: Infinity,
      repeatType: "reverse",
      duration: 2.5
    }
  }
}

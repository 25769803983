import { HoverTrigger, ScaleBox } from "shared/components/anime"

import { Container } from "./layout"
import { Link } from "react-router-dom"
import { ProjectContext } from "./content"
import { Swiper } from "shared/components/swiper"
import { chunk } from "lodash"
import { cx } from "shared/helpers"
import { nanoid } from "nanoid"
import { useContext } from "react"

export { Gallery }

function Gallery({ meta, value }) {
  const { type } = meta.variant

  const Component = components.get(type)
  const props = createProps(type, value)

  return Component ? (
    <Container>
      <Component value={props} />
    </Container>
  ) : null
}

function Slider({ value }) {
  const { projectId } = useContext(ProjectContext)

  return (
    <Swiper
      id={"content-builder-swiper-" + nanoid()}
      options={{ slidesPerView: "auto", spaceBetween: 8, cssMode: true }}
    >
      {value.map(image => (
        <HoverTrigger key={image.hash}>
          <ScaleBox className="h-[160px] md:h-[465px] 2xl:h-[670px]">
            <Link to={`/gallery/projects/${projectId}/${image.hash}`}>
              <img src={image.url} className="img-cover w-auto" fetchpriority="high" alt="" />
            </Link>
          </ScaleBox>
        </HoverTrigger>
      ))}
    </Swiper>
  )
}

function SingleImage({ value, showGallery = false }) {
  const { projectId } = useContext(ProjectContext)

  return value ? (
    <div className="aspect-[1840/865]">
      <HoverTrigger>
        <ScaleBox>
          {showGallery ? (
            <Link to={`/gallery/projects/${projectId}/${value.hash}`}>
              <img src={value.url} className="img-cover" alt="" />
            </Link>
          ) : (
            <img src={value.url} className="img-cover" alt="" />
          )}
        </ScaleBox>
      </HoverTrigger>
    </div>
  ) : null
}

function DoubleImage({ value }) {
  const [image1, image2] = value
  return (
    <div className="grid gap-[20px] grid-cols-2">
      {image1 && <SingleImage value={image1} showGallery />}
      {image2 && <SingleImage value={image2} showGallery />}
    </div>
  )
}

function ImageGrid({ value }) {
  const { projectId } = useContext(ProjectContext)
  return (
    <>
      <div className="hidden md:block">
        {chunk(value, IMAGES_PER_ROW).map((row, index) => (
          <div
            key={index}
            className={cx("grid cursor-pointer grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr] gap-[20px]", {
              "mt-[20px]": index > 0,
              "pr-[calc(100vw/6)]": index % 2 === 0,
              "pl-[calc(100vw/6)]": index % 2 !== 0
            })}
          >
            {row.map((image, index) => (
              <HoverTrigger className="aspect-[163/163]" key={index}>
                <ScaleBox>
                  <Link to={`/gallery/projects/${projectId}/${image.hash}`}>
                    <img src={image.url} className="img-cover" alt="" />
                  </Link>
                </ScaleBox>
              </HoverTrigger>
            ))}
          </div>
        ))}
      </div>
      <div className="md:hidden">
        <div className="grid grid-cols-3 gap-[10px]">
          {value.map((image, index) => (
            <div className="aspect-[90/90]" key={index}>
              <Link to={`/gallery/projects/${projectId}/${image.hash}`}>
                <img src={image.url} className="img-cover" alt="" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const components = new Map([
  ["double", DoubleImage],
  ["single", SingleImage],
  ["tiles", ImageGrid],
  ["slider", Slider]
])

const createProps = (type, value) => {
  return {
    single: value[0],
    double: value.slice(0, 2),
    tiles: value,
    slider: value
  }[type]
}

const IMAGES_PER_ROW = 6

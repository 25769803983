import { HoverTrigger, ScaleBox } from "shared/components/anime"

export function ProjectCard({ name, address, year, picture }) {
  return (
    <HoverTrigger className="project-card">
      <div className="h3 overflow-hidden text-ellipsis whitespace-nowrap">{name}</div>
      <div className="my-[10px]">
        <ScaleBox className="aspect-[290/163]">
          <img src={picture} className="img-cover" alt="" />
        </ScaleBox>
      </div>
      <div className="h3 overflow-hidden text-ellipsis whitespace-nowrap">{address}</div>
      <div className="h3">{year}</div>
    </HoverTrigger>
  )
}

import { format, parseISO } from "date-fns"

import { ReactComponent as GalkaIcon } from "../assets/galka.svg"
import { ReactComponent as KrestikIcon } from "../assets/krestik.svg"
import { Link } from "react-router-dom"
import { UserRespondStatusEnum } from "../constants"

// TODO: применить неактивное состояние
export function TenderCard({ name, dateStart, dateEnd, spheres, href, respondStatus }) {
  return (
    <Link
      to={href}
      className="p-[20px] md:p-[30px] 2xl:p-[40px] border-2 border-gray-900 dark:border-white dark:hover:bg-white hover:bg-gray-900 hover:text-white dark:hover:text-gray-900 group"
    >
      <div className="md:flex">
        <div className="flex-1">
          <div className="h3">
            {dateStart && <span>{format(parseISO(dateStart), "dd.MM.yyyy")}</span>}
            {dateEnd && <span> – {format(parseISO(dateEnd), "dd.MM.yyyy")}</span>}
            <span> г.</span>
          </div>
          {spheres?.length > 0 && (
            <div className="mt-[15px] grid gap-[7px] dark:text-white/50 text-gray-900/50 group-hover:text-white/50 dark:group-hover:text-gray-900/50 uppercase text-[11px] leading-[160%]">
              {spheres.map(name => (
                <div key={name}>{name}</div>
              ))}
            </div>
          )}
          <div className="h2 mt-[30px] 2xl:mt-[40px]">{name}</div>
        </div>
        {respondStatus && (
          <div className="mt-[24px] md:mt-[0px]">
            <RespondStatus name={respondStatus.name} type={respondStatus.state} />
          </div>
        )}
      </div>
    </Link>
  )
}

function RespondStatus({ name, type }) {
  const statusIcon = getStatusIcon(type)
  return (
    <span className="flex items-center">
      <div className="w-[30px] h-[30px] flex items-center justify-center">{statusIcon}</div>
      <div className="pl-[20px] pt-[0.25em] text-[15px]">{name}</div>
    </span>
  )
}

function getStatusIcon(status) {
  return {
    [UserRespondStatusEnum.New]: <GalkaIcon className="block text-[#98B5D5]" />,
    [UserRespondStatusEnum.Deal]: <GalkaIcon className="block text-[#78CB5B]" />,
    [UserRespondStatusEnum.Rejected]: <KrestikIcon className="block text-[#FE2525]" />
  }[status]
}

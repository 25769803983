import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"

export function ForgotPasswordOk() {
  return (
    <div className="container-x container-y">
      <div className="md:pl-[calc(50%+20px)]">
        <div className="text-center md:text-left">
          <div className="h1">Ссылка для сброса пароля отправлена</div>
        </div>
        <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
          <InviewTrigger>
            <RotateBox>
              <Link className="btn-b no-underline" to="/">
                на главную
              </Link>
            </RotateBox>
          </InviewTrigger>
        </div>
      </div>
    </div>
  )
}

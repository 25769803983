import { THEME_DARK, THEME_LIGHT } from "./constants"

import create from "zustand"
import { persist } from "zustand/middleware"

const themeSlice = set => ({
  theme: null,
  setDarkTheme: () => set({ theme: THEME_DARK }),
  setLightTheme: () => set({ theme: THEME_LIGHT }),
  toggleTheme: () => set(state => ({ theme: toggleTheme(state.theme) }))
})

const cookieSlice = set => ({
  menuIsOpen: true,
  closeMenu: () => set({ menuIsOpen: false })
})

export const useCookieStore = create(
  persist(cookieSlice, {
    name: "cookie-store",
    getStorage: () => window.localStorage
  })
)

export const useThemeStore = create(
  persist(themeSlice, {
    name: "theme-store",
    getStorage: () => window.localStorage
  })
)

const toggleTheme = cur => (cur === THEME_DARK ? THEME_LIGHT : THEME_DARK)

import { InviewTrigger, RotateBox } from "shared/components/anime"

import { ContentBuilder } from "../components/content"
import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { safeJSONParse } from "shared/helpers"
import { useParams } from "react-router-dom"
import { useProject } from "../api"

export function Project() {
  const id = useParams().id
  const { data: { data: project, meta } = {} } = useProject({ id })
  const content = safeJSONParse(project?.content) ?? []

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      {project && (
        <>
          <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
            <h1 className="h1">{project.name}</h1>
          </div>
          <div className="mt-[20px] grid-cols-10 gap-[40px] md:mt-[60px] md:grid 2xl:mt-[100px]">
            <div className="col-span-5">
              <div className="h3">{project.year}</div>
              <div className="h2 mt-[10px] md:mt-[20px]">{project.city}</div>
            </div>
            <div className="col-span-5 mt-[20px] md:mt-[0px]">
              <div className="txt">{project.annotation}</div>
            </div>
          </div>
          {project.content && <ContentBuilder blocks={content} projectId={project.id} />}
        </>
      )}
      <div className="mt-[20px] text-right md:mt-[80px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link to="/projects" className="btn-b no-underline">
              проекты
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </div>
  )
}

import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"
import { format, parseISO } from "date-fns"
import { useCallback, useState } from "react"

import { Files } from "../components/files"
import { Link } from "react-router-dom"
import { ProposalForm } from "../components/proposal-form"
import { sanitizeHTML } from "shared/helpers"
import { useNotifications } from "features/misc"
import { useParams } from "react-router-dom"
import { useTender } from "../api"

export function Tender() {
  const id = useParams().id
  const { data: tender } = useTender({ id })

  const addNotification = useNotifications(state => state.addNotification)

  const [formIsOpen, setFormIsOpen] = useState(false)
  const openForm = () => setFormIsOpen(true)
  const closeForm = useCallback(() => setFormIsOpen(false), [])

  const onSubmitSuccess = useCallback(() => {
    addNotification({ message: "Заявка отправлена" })
    closeForm()
  }, [closeForm, addNotification])

  return (
    <div className="container-x container-y">
      {tender && (
        <>
          <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
            <h1 className="h1">{tender.name}</h1>
          </div>
          <div className="mt-[40px] md:mt-[80px] 2xl:mt-[100px]">
            <div className="grid-cols-10 gap-[40px] md:grid">
              <div className="col-span-5 hidden md:block">
                <TendersLink />
              </div>
              <div className="col-span-5">
                <div className="flex">
                  <div className="h2 flex md:block flex-col-reverse">
                    <span className="">
                      {tender.startAt && (
                        <span>{format(parseISO(tender.startAt), "dd.MM.yyyy")}</span>
                      )}
                      {tender.finishAt && (
                        <span> – {format(parseISO(tender.finishAt), "dd.MM.yyyy")}</span>
                      )}
                    </span>
                    <span className="h3 dark:text-white/50 text-gray-900/50">
                      <span className="hidden md:inline"> – </span>
                      период приема заявок
                    </span>
                  </div>
                </div>
                {tender.sphereNames.length > 0 && (
                  <div className="grid gap-[5px] mt-[20px] md:mt-[40px]">
                    {tender.sphereNames.map(name => (
                      <div
                        key={name}
                        className="text-xs dark:text-white/50 text-gray-900/50 uppercase"
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="txt mt-[20px] md:mt-[40px] whitespace-pre-line dont-break-out"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(tender.description) }}
                />
                {tender.files.length > 0 && (
                  <div className="mt-[40px] md:mt-[60px]">
                    <Files files={tender.files} />
                  </div>
                )}
                {formIsOpen ? (
                  <div className="mt-[60px] md:mt-[100px] 2xl:mt-[140px]">
                    <ProposalForm tenderId={id} onSubmitSuccess={onSubmitSuccess} />
                  </div>
                ) : (
                  <div className="mt-[40px] md:mt-[60px] 2xl:mt-[100px]">
                    <div className="flex justify-between md:justify-end items-end">
                      <div className="md:hidden">
                        <TendersLink />
                      </div>
                      {tender.userState === null && (
                        <InviewTrigger>
                          <RotateBox>
                            <button className="btn-b no-underline" onClick={openForm}>
                              Откликнуться
                            </button>
                          </RotateBox>
                        </InviewTrigger>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

function TendersLink() {
  return (
    <HoverTrigger>
      <Underline reverse>
        <Link to="/cabinet/tenders" className="btn no-underline">
          Все заказы
        </Link>
      </Underline>
    </HoverTrigger>
  )
}

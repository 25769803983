import { format, isValid, parse } from "date-fns"

import { isObject } from "lodash"
import { useLocation } from "react-router-dom"
import { useMemo } from "react"

export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ]
}

export function safeJSONParse(json) {
  try {
    return JSON.parse(json)
  } catch (ex) {
    return null
  }
}

export function addProps(WrappedComponent, props) {
  return () => <WrappedComponent {...props} />
}

export { default as cx } from "classnames"

export function confirmDialog(msg) {
  return new Promise(function (resolve, reject) {
    const confirmed = window.confirm(msg)
    return confirmed && resolve(true)
  })
}

export function sanitizeHTML(html) {
  return html
}

export function makeValidation(error, setter, whitelist = []) {
  if (error.response.status === 412) {
    const { errors } = error.response.data
    if (isObject(errors)) {
      for (const [key, error] of Object.entries(errors)) {
        if (whitelist.length > 0 && whitelist.indexOf(key) === -1) {
          continue
        }
        setter(key, {
          type: "custom",
          message: [].concat(error).join(". ")
        })
      }
    }
  }
}

export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, l => `_${l.toLowerCase()}`)
}

export function useQueryParams() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export const Enum = obj => {
  const keysByValue = new Map()
  const EnumLookup = value => keysByValue.get(value)

  for (const key of Object.keys(obj)) {
    EnumLookup[key] = obj[key]
    keysByValue.set(EnumLookup[key], key)
  }

  return Object.freeze(EnumLookup)
}

export function dateFromToFormat(dateStr, fromFormat = "dd.MM.yyyy", toFormat = "yyyy-MM-dd") {
  const dateObj = parse(dateStr, fromFormat, new Date())
  return isValid(dateObj) ? format(dateObj, toFormat) : null
}

import { Checkbox, ControlLabel, Fieldset, Form, PhoneField } from "shared/components/form"
import { InviewTrigger, RotateBox } from "shared/components/anime"
import { TextField, TextareaField } from "shared/components/form"

import { Controller } from "react-hook-form"
import { SignUpOk } from "../components/sign-up-ok"
import { SuggestSignIn } from "../components/suggest-sign-in"
import { makeValidation } from "shared/helpers"
import { useSignUpUser } from "../api"
import { useSpheres } from "shared/api"

export function SignUp() {
  const { data: spheres } = useSpheres()

  const { mutate: signUpUser, isSuccess, isLoading } = useSignUpUser()
  const submitForm = (payload, { setError }) => {
    signUpUser(payload, {
      onError: error => makeValidation(error, setError)
    })
  }

  if (isSuccess) {
    return <SignUpOk />
  }

  return (
    <Form onSubmit={submitForm} options={{ defaultValues: { spheres: [] } }}>
      {({ register, formState, control, setValue, watch }) => (
        <div className="container-x container-y">
          <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
            <div className="h1">Регистрация</div>
          </div>
          <div className="mt-[20px] md:hidden">
            <SuggestSignIn />
          </div>
          <div className="mt-[20px] md:mt-[80px] 2xl:mt-[100px]">
            <div className="grid-cols-10 gap-[40px] md:grid">
              <div className="col-span-5 hidden md:block">
                <SuggestSignIn />
              </div>
              <div className="col-span-4">
                <div>
                  <TextField
                    placeholder="фио/название компании"
                    errorMessage={formState.errors.name?.message}
                    {...register("name")}
                  />
                </div>
                <div className="mt-[20px]">
                  <Controller
                    name="phone"
                    control={control}
                    render={() => (
                      <PhoneField
                        placeholder="телефон"
                        errorMessage={formState.errors.phone?.message}
                        onValueChange={values => setValue("phone", values.value)}
                      />
                    )}
                  />
                </div>
                <div className="mt-[20px]">
                  <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                    *Email будет использоваться для авторизации в личном кабинете партнера.
                  </div>
                  <div className="mt-[10px]">
                    <TextField
                      placeholder="email"
                      errorMessage={formState.errors.email?.message}
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="mt-[20px]">
                  <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                    *Отметьте галочкой направления, в которых специализируютесь.
                  </div>
                </div>
                {spheres && (
                  <div className="mt-[10px]">
                    <Fieldset
                      label="Направления"
                      active={watch("spheres").length > 0}
                      errorMessage={formState.errors.spheres?.message}
                    >
                      <div className="mt-[10px] md:mt-[20px]">
                        {spheres.map(sphere => (
                          <div key={sphere.id} className="mt-[10px]">
                            <ControlLabel label={sphere.name}>
                              <Checkbox value={sphere.id} {...register("spheres")} />
                            </ControlLabel>
                          </div>
                        ))}
                      </div>
                    </Fieldset>
                  </div>
                )}
                <div className="mt-[20px]">
                  <TextareaField
                    rows="4"
                    label="Расскажите о себе"
                    placeholder="Описание"
                    errorMessage={formState.errors.description?.message}
                    {...register("description")}
                  />
                </div>
                <div className="mt-[20px]">
                  <TextField
                    placeholder="Cсылка на портфолио"
                    errorMessage={formState.errors.portfolioLink?.message}
                    {...register("portfolioLink")}
                  />
                </div>
              </div>
            </div>
            <div className="grid-cols-10 gap-[40px] md:grid mt-[40px] md:mt-[60px] md:text-right 2xl:mt-[100px]">
              <div className="col-start-6 col-end-11">
                <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                  *Нажимая кнопку отправить, вы даете свое                             <a
                              href={`${process.env.PUBLIC_URL}/docs/soglasie.pdf`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              согласие на обработку персональных данных
                            </a>
                </div>
                <div className="mt-[10px] text-right">
                  <InviewTrigger>
                    <RotateBox>
                      <button type="submit" disabled={isLoading} className="btn-b no-underline">
                        отправить
                      </button>
                    </RotateBox>
                  </InviewTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  )
}

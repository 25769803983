import { AchievementCard } from "../components/achievement-card"
import { MetaTags } from "shared/components/seo"
import { useAchievements } from "../api"

export function Achievements() {
  const { data: { data: achievements, meta } = {} } = useAchievements()
  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">Достижения</h1>
      </div>
      {achievements && (
        <div className="mt-[20px] grid gap-[40px] md:mt-[60px] md:gap-[50px] 2xl:mt-[100px] 2xl:gap-[80px]">
          {achievements.map(achievement => (
            <AchievementCard
              key={achievement.id}
              id={achievement.id}
              name={achievement.name}
              image={achievement.pictureUrl}
            />
          ))}
        </div>
      )}
    </div>
  )
}

import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"

export function ChangePasswordOk() {
  return (
    <div className="container-x container-y">
      <div className="md:pl-[calc(50%+20px)]">
        <div className="text-center md:text-left">
          <div className="h1">Пароль изменён</div>
        </div>
        <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
          <InviewTrigger>
            <RotateBox>
              <Link className="btn no-underline" to="/cabinet/profile/edit">
                Вернуться в Профиль
              </Link>
            </RotateBox>
          </InviewTrigger>
        </div>
      </div>
    </div>
  )
}

import {
  HoverTrigger,
  InviewTrigger,
  RotateBox,
  Underline
} from "shared/components/anime"

import { useOffices } from "../api"

export function Contacts() {
  const { data: { data: offices } = {} } = useOffices()
  return (
    <div className="container-x container-y md:flex md:flex-1 md:flex-col">
      {offices && (
        <>
          <div className="md:flex-1 md:pl-[calc(50%+10px)]">
            {offices.phone && (
              <div className="h1">
                <a href={`tel:${offices.phone}`}>{offices.phone}</a>
              </div>
            )}
            {offices.email && (
              <div className="h2 mt-[20px] md:mt-[10px] 2xl:mt-[30px]">
                <HoverTrigger>
                  <Underline>
                    <a href={`mailto:${offices.email}`}>{offices.email}</a>
                  </Underline>
                </HoverTrigger>
              </div>
            )}
          </div>
          <div className="mt-[50px]">
            <div
              className="h3 whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: offices.address }}
            />
            {offices.coordinates && (
              <div className="mt-[10px]">
                <InviewTrigger>
                  <RotateBox>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn no-underline"
                      href={`https://maps.yandex.com/?ll=${offices.coordinates[0]}, ${offices.coordinates[1]}&z=18`}
                    >
                      смотреть на карте
                    </a>
                  </RotateBox>
                </InviewTrigger>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useAchievements, useAchievement }

function getAchievement(id) {
  return http(`/achievements/${id}`)
}

function getAchievements(query = {}) {
  return http("/achievements", {
    params: {
      limit: query.limit
    }
  })
}

function useAchievements({ query, config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["achievements", query],
    queryFn: () => getAchievements(query)
  })
}

function useAchievement({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["achievement", id],
    queryFn: () => getAchievement(id),
    useErrorBoundary: true
  })
}

import { QueryClient } from "react-query"

const queryConfig = {
  queries: {
    retry: 2
  }
}

export const queryClient = new QueryClient({
  defaultOptions: queryConfig
})

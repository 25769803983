import { Form, PasswordField } from "shared/components/form"
import { InviewTrigger, RotateBox } from "shared/components/anime"
import { makeValidation, useQueryParams } from "shared/helpers"

import { ResetPasswordOk } from "../components/reset-password-ok"
import { useResetPassword } from "../api"

export function ResetPassword() {
  const queryParams = useQueryParams()
  const hash = queryParams.get("hash")

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword({ hash })
  const submitForm = (payload, { setError }) => {
    resetPassword(payload, {
      onError: error => makeValidation(error, setError)
    })
  }

  if (isSuccess) {
    return <ResetPasswordOk />
  }

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <div className="h1">Создание пароля</div>
        <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px]" />
        <Form onSubmit={submitForm}>
          {({ register, formState }) => (
            <>
              <PasswordField
                placeholder="введите пароль"
                errorMessage={formState.errors.password?.message}
                {...register("password")}
              />
              <div className="mt-[20px]">
                <PasswordField
                  placeholder="повторите пароль"
                  errorMessage={formState.errors.passwordConfirmation?.message}
                  {...register("passwordConfirmation")}
                />
              </div>
              <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
                <InviewTrigger>
                  <RotateBox>
                    <button type="submit" disabled={isLoading} className="btn-b no-underline">
                      отправить
                    </button>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

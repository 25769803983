import { BannersWidget } from "features/misc"

export function Hero() {
  return (
    <>
      <BannersWidget />
      <div className="mt-[40px] md:mt-[100px] md:pl-[calc(50%+20px)] 2xl:mt-[200px]">
        <div className="h1">запечатляем время в пространстве. Создаем среду и формируем будущее, опережая время.</div>
      </div>
      <div className="mt-[40px] px-[40px] md:mt-[100px] 2xl:mt-[200px]">
        <div className="m-auto md:w-[612px] 2xl:w-[866px]">
          <div className="aspect-[216/205]">
            <img src={require("../assets/2.svg").default} className="img-cover dark:hidden" alt="" />
            <img src={require("../assets/1.svg").default} className="img-cover hidden dark:block" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

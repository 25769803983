import { Container, Layout } from "./layout"

import { sanitizeHTML } from "shared/helpers"

export { Heading, Text }

function Heading({ value, meta }) {
  const Component = components.get(meta.variant)
  return Component ? (
    <Container>
      <Layout>
        <Component value={value} />
      </Layout>
    </Container>
  ) : null
}

function H1({ value }) {
  return <h2 className="h1">{value}</h2>
}

function H2({ value }) {
  return <h3 className="h2">{value}</h3>
}

function Text({ value }) {
  const __html = sanitizeHTML(value)
  return (
    <Container>
      <Layout>
        <p className="txt [&>a]:underline" dangerouslySetInnerHTML={{ __html }} />
      </Layout>
    </Container>
  )
}

const components = new Map([
  ["h1", H1],
  ["h2", H2]
])

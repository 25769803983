import { confirmDialog } from "shared/helpers"
import { useAuthStore } from "../store"
import { useNavigate } from "react-router-dom"

export function useLogout() {
  const navigate = useNavigate()
  const logout = useAuthStore(state => state.logout)

  return async () => {
    try {
      await confirmDialog("Вы уверены?")
      navigate("/")
      logout()
    } catch (error) {}
  }
}

import { Form, PasswordField, TextField } from "shared/components/form"
import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { SuggestSignUp } from "../components/suggest-sign-up"
import { useAuthStore } from "../store"
import { useNavigate } from "react-router-dom"
import { useSignInUser } from "../api"

export function SignIn() {
  const navigate = useNavigate()
  const authorize = useAuthStore(state => state.authorize)

  const { mutate: signInUser, isLoading } = useSignInUser()
  const submitForm = payload => {
    signInUser(payload, {
      onSuccess: resp => {
        authorize(resp.data.token)
        navigate("/cabinet")
      }
    })
  }

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <div className="h1">Добро пожаловать!</div>
      </div>
      <Form onSubmit={submitForm}>
        {({ register }) => (
          <div className="grid-cols-10 gap-[40px] md:mt-[100px] md:grid">
            <div className="col-span-5 hidden md:flex">
              <SuggestSignUp />
            </div>
            <div className="col-span-5">
              <div className="mt-[20px] md:mt-[0px]">
                <div className="grid-cols-5 gap-[40px] md:grid">
                  <div className="col-span-4">
                    <TextField placeholder="введите e-mail" {...register("login")} />
                    <div className="mt-[20px]">
                      <PasswordField placeholder="введите пароль" {...register("password")} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[20px] flex items-center justify-between md:mt-[60px]">
                <HoverTrigger>
                  <Underline reverse>
                    <Link className="btn no-underline" to="/auth/forgot-password">
                      забыли пароль?
                    </Link>
                  </Underline>
                </HoverTrigger>
                <InviewTrigger>
                  <RotateBox>
                    <button type="submit" disabled={isLoading} className="btn-b no-underline">
                      войти
                    </button>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </div>
          </div>
        )}
      </Form>
      <div className="mt-[100px] md:hidden">
        <SuggestSignUp />
      </div>
    </div>
  )
}

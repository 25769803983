import { Enum } from "shared/helpers"
// new  => Откликнулся
// rejected => Отклонен
// deal => Исполнитель
export const NEW = "new"
export const REJECTED = "rejected"
export const DEAL = "deal"
export const ACTIVE = "active"

export const UserRespondStatusEnum = Enum({
  New: NEW,
  Deal: DEAL,
  Rejected: REJECTED
})

export const TenderStatusEnum = Enum({
  Active: ACTIVE
})

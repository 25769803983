import { Form, TextField } from "shared/components/form"
import { InviewTrigger, RotateBox } from "shared/components/anime"

import { ForgotPasswordOk } from "../components/forgot-password-ok"
import { makeValidation } from "shared/helpers"
import { useForgotPassword } from "../api"

export function ForgotPassword() {
  const { mutate: forgotPassword, isLoading, isSuccess } = useForgotPassword()
  const submitForm = (payload, { setError }) => {
    forgotPassword(payload, {
      onError: error => makeValidation(error, setError)
    })
  }

  if (isSuccess) {
    return <ForgotPasswordOk />
  }

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <Form onSubmit={submitForm}>
          {({ register, formState }) => (
            <>
              <div className="h1">восстановление пароля</div>
              <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
                <div className="text-[12px] leading-[160%] md:text-[14px] 2xl:text-[15px]">
                  *Введите Email, к которому привязан аккаунт. Через несколько минут, Вам на почту
                  поступит ссылка для восстановления пароля.
                </div>
              </div>
              <div className="mt-[10px] md:mt-[20px]">
                <TextField
                  placeholder="введите Email"
                  errorMessage={formState.errors.email?.message}
                  {...register("email")}
                />
              </div>
              <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
                <InviewTrigger>
                  <RotateBox>
                    <button type="submit" disabled={isLoading} className="btn-b no-underline">
                      отправить
                    </button>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

import { HoverTrigger, InviewTrigger, RotateBox, ScaleBox } from "shared/components/anime"

import { Link } from "react-router-dom"
import { ReactComponent as LogoSm } from "../assets/2.svg"
import { ReactComponent as LogoXl } from "../assets/12.svg"
import { Swiper } from "shared/components/swiper"

export function About() {
  return (
    <div className="container-x container-y">
      <div className="text-center md:text-left">
        <h1 className="h1 md:pl-[calc(50%+10px)]">О бюро</h1>
        <div className="mt-[20px] aspect-[288/162] md:mt-[16px] md:aspect-[1286/465] 2xl:aspect-[1840/670]">
          <img loading="lazy" src={require("../assets/16.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[40px] md:mt-[100px]">
        <div className="h1 md:pl-[calc(50%+10px)]">Кто мы?</div>
        <div className="m-auto mt-[40px] md:mt-[100px] md:w-[60%] 2xl:mt-[160px]">
          <div className="aspect-[288/76] md:aspect-[764/202] 2xl:aspect-[1096/306]">
            <LogoSm className="h-full w-full md:hidden" />
            <LogoXl className="hidden h-full w-full md:block" />
          </div>
        </div>
        <div className="h1 mt-[40px] md:mt-[100px] md:pl-[calc(50%+10px)] 2xl:mt-[160px]">
          -компания, в которой важна забота о человеке, честность, профессионализм и стратегический подход.
        </div>
        <div className="md:hidden">
          <div className="mt-[40px] mb-[40px]">
            <div className="m-auto w-[50%]">
              <div className="aspect-[133/256]">
                <img loading="lazy" src={require("../assets/3.jpg")} className="img-cover" alt="" />
              </div>
            </div>
          </div>
          <div className="mt-[40px] mb-[40px]">
            <div className="aspect-[288/162]">
              <img loading="lazy" src={require("../assets/7.png")} className="img-cover" alt="" />
            </div>
          </div>
        </div>
        <div className="2xl:mt-[16 0px] mt-[100px] hidden grid-cols-10 gap-5 md:grid">
          <div className="col-span-2">
            <div className="h-[465px] 2xl:h-[670px]">
              <img loading="lazy" src={require("../assets/14.jpg")} className="img-cover" alt="" />
            </div>
          </div>
          <div className="col-span-8">
            <div className="h-[465px] 2xl:h-[670px]">
              <img loading="lazy" src={require("../assets/10.jpg")} className="img-cover" alt="" />
            </div>
          </div>
        </div>
        <div className="h2 mt-[60px] mb-[40px] md:mt-[100px] md:mb-[80px] md:pl-[calc(50%+10px)] 2xl:mt-[200px] 2xl:mb-[100px]">
          Что мы делаем?
        </div>
        <div className="grid-cols-10 gap-5 md:grid">
          <div className="col-span-1" />
          <div className="col-span-2">
            <div className="xw-1/5">
              <div className="hidden aspect-[352/618] md:block">
                <img loading="lazy" src={require("../assets/5.jpg")} className="img-cover" alt="" />
              </div>
            </div>
          </div>
          <div className="col-span-2" />
          <div className="col-span-5">
            <div className="h1">миссия a|U - оставить после себя нерушимое наследие.</div>
            <div className="h1 my-[40px] text-xl md:my-[20px]">
              оно станет базисом для жизни и развития следующих поколений.
            </div>
            <div className="txt mt-[24px] md:mt-[60px] 2xl:mt-[100px]">
              Архитектурно-проектное бюро <b>«Арктиект бай Унистрой»</b> на протяжении пяти лет успешно реализует
              проекты для жилой и коммерческой недвижимости в сфере девелопмента.
            </div>
            <div className="mt-[20px] mb-[20px] md:hidden">
              <div className="aspect-[288/470]">
                <img loading="lazy" src={require("../assets/5.jpg")} className="img-cover" alt="" />
              </div>
            </div>
            <div className="txt">
              Предметом деятельности АПБ является: разработка концепции будущего объекта, генеральный план, эскизное
              проектирование, выпуск проекта по благоустройству, цветовые решения фасадов и разработка стадийного проекта.
              <br />
              <br />
              Одним из ключевых показателей работы АПБ ЭЙЮ является общая площадь качественно запроектированных и
              введённых в эксплуатацию объектов недвижимости с коммерческим блоком которые были высоко оценены ведущими
              российскими архитекторами в том числе муниципальными властями Республики Татарстан (Казаныш).
            </div>
          </div>
        </div>
        <div className="mt-[20px] text-right md:mt-[60px]">
          <InviewTrigger>
            <RotateBox>
              <Link to="/stories" className="btn-b no-underline">
                история бюро
              </Link>
            </RotateBox>
          </InviewTrigger>
        </div>
        <div className="mt-[60px] md:mt-[100px] 2xl:mt-[200px]">
          <div className="h2 md:pl-[calc(50%+10px)]">Как мы делаем?</div>
          <div className="mt-[20px] mb-[20px] 2xl:mb-[100px]">
            <Swiper
              id="about-swiper"
              options={{
                spaceBetween: 16,
                slidesPerView: 1,
                effect: "fade",
                fadeEffect: { crossFade: true },
                speed: 800
              }}
            >
              <HoverTrigger>
                <ScaleBox className="aspect-[288/162] md:aspect-[1286/465]">
                  <img loading="lazy" src={require("../assets/17.jpg")} className="img-cover" alt="" />
                </ScaleBox>
              </HoverTrigger>
              <HoverTrigger>
                <ScaleBox className="aspect-[288/162] md:aspect-[1286/465]">
                  <img loading="lazy" src={require("../assets/18.jpg")} className="img-cover" alt="" />
                </ScaleBox>
              </HoverTrigger>
              <HoverTrigger>
                <ScaleBox className="aspect-[288/162] md:aspect-[1286/465]">
                  <img loading="lazy" src={require("../assets/19.jpg")} className="img-cover" alt="" />
                </ScaleBox>
              </HoverTrigger>
            </Swiper>
          </div>
          <div className="txt md:pl-[calc(50%+10px)]">
            Главная ценность АПБ A|U - качество социальной и городской среды, повышение уровня комфорта населения,
            экология, развитие интереса к физической культуре подрастающего поколения, внедрение инновационных
            технических и архитектурных решений.
            <br />
            <br />
            Ключевая задача на ближайшую перспективу команды A|U реализация ряда мероприятий автоматизированного
            проектирования и применение современного ПО в проектировании.
          </div>
        </div>
        <div className="mt-[60px] md:mt-[100px] 2xl:mt-[200px]">
          <div className="h1">руководитель бюро</div>
          <div className="grid-cols-10 gap-5 md:mt-[20px] md:grid">
            <div className="col-span-4 mt-[20px] mb-[20px] md:mt-[0px] md:mb-[0px]">
              <div className="aspect-[288/288]">
                <img loading="lazy" src={require("../assets/11.jpg")} className="img-cover" alt="" />
              </div>
            </div>
            <div className="col-span-6 flex-col md:flex">
              <div className="flex-1 items-center justify-center md:flex">
                <div className="md:max-w-[385px] 2xl:max-w-[540px]">
                  <div className="h2 mb-[20px] 2xl:mb-[40px]">
                    “Мы дорожим своим именем и своей репутацией, уважаем наших партнеров и заказчиков.”
                  </div>
                  <div className="h2">Латыпов Ринат</div>
                </div>
              </div>
              <div className="mt-[20px] text-right">
                <InviewTrigger>
                  <RotateBox>
                    <Link to="/team" className="btn no-underline">
                      подробнее о команде
                    </Link>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { HoverTrigger, ScaleBox, Underline } from "shared/components/anime"
import { format, parseISO } from "date-fns"

import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

export function NewsCard({ datetime, name, picture, id }) {
  const navigate = useNavigate()
  const href = `/news/${id}`

  return (
    <HoverTrigger className="border-2 border-gray-900 p-[20px] transition-all duration-500 hover:bg-gray-900 hover:text-white dark:border-white dark:hover:bg-white dark:hover:text-gray-900 md:p-[40px]">
      <div
        className="md:grid md:min-h-[220px] md:grid-cols-10 2xl:min-h-[320px]"
        onClick={() => navigate(href)}
      >
        <div className="md:col-span-6 md:flex md:flex-col">
          <div className="md:flex-1">
            <div className="h3">{format(parseISO(datetime), "dd.MM.yyyy")} г.</div>
            <div className="h2 mt-[20px]">{name}</div>
            {picture && (
              <div className="md:hidden">
                <div className="mt-[20px] -mb-[20px] aspect-[268/165]">
                  <img loading="lazy" src={picture} className="img-cover" alt="" />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="mt-[40px]">
              <Underline reverse>
                <Link className="btn no-underline" to={href} onClick={ev => ev.stopPropagation()}>
                  читать
                </Link>
              </Underline>
            </div>
          </div>
        </div>
        <div className="md:col-span-3 md:col-start-8 md:flex">
          {picture && (
            <div className="relative hidden md:flex md:flex-1">
              <ScaleBox>
                <img loading="lazy" src={picture} className="img-abs-cover" alt="" />
              </ScaleBox>
            </div>
          )}
        </div>
      </div>
    </HoverTrigger>
  )
}

import { Toaster as HotToast, toast } from "react-hot-toast"

import { Toast } from "./toast"
import { useEffect } from "react"
import { useNotifications } from "../store"

export function Toaster() {
  const notifications = useNotifications(state => state.notifications)
  const removeNotification = useNotifications(state => state.removeNotification)

  useEffect(() => {
    for (const { id, type, message } of notifications) {
      toast.custom(t => <Toast message={message} type={type} visible={t.visible} />, {
        position: "top-left",
        id
      })
      removeNotification(id)
    }
  }, [notifications, removeNotification])

  return <HotToast />
}

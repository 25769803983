import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"

export function ResetPasswordOk() {
  return (
    <div className="container-x container-y">
      <div className="md:pl-[calc(50%+20px)]">
        <div className="text-center md:text-left">
          <div className="h1">сохранено</div>
        </div>
        <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
          <div className="h2">
            Новый пароль успешно сохранен. Теперь вы можете авторизоваться на сайте.
          </div>
        </div>
        <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
          <InviewTrigger>
            <RotateBox>
              <Link to="/auth/sign-in" className="btn-b no-underline">
                Войти
              </Link>
            </RotateBox>
          </InviewTrigger>
        </div>
      </div>
    </div>
  )
}

import { useRef, useState } from "react"

import { cx } from "shared/helpers"
import { motion } from "framer-motion"
import { useUnderline } from "../hooks/use-underline"

export function RotateBox({ children, className }) {
  const [hover, setHover] = useState(false)

  const $text = useRef()
  const { borderWidth } = useUnderline($text)

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cx("relative inline-flex overflow-hidden", className, {
        "pt-[2px]": borderWidth >= 2
      })}
    >
      <motion.span variants={textMotion} className="origin-top-left">
        <motion.span
          custom={{ hover, borderWidth }}
          variants={underlineMotion}
          className={cx("fancy-underline", className)}
        >
          <span ref={$text} className={cx("relative", { "-top-[2px]": borderWidth >= 2 })}>
            {children}
          </span>
        </motion.span>
      </motion.span>
    </div>
  )
}

const underlineMotion = {
  initial: ({ borderWidth }) => ({
    backgroundSize: `0% ${borderWidth}px`
  }),
  animate: ({ hover, borderWidth }) => {
    return {
      backgroundSize: `100% ${borderWidth}px`,
      transition: {
        delay: hover ? 0 : 0.6,
        duration: 0.4
      }
    }
  },
  hover: ({ borderWidth }) => ({
    backgroundSize: `0% ${borderWidth}px`,
    transition: {
      delay: 0,
      duration: 0.4
    }
  })
}

const textMotion = {
  initial: {
    y: "150%",
    rotate: 40,
    transition: {
      duration: 0.6
    }
  },
  animate: {
    y: 0,
    rotate: 0,
    transition: {
      duration: 0.6
    }
  }
}

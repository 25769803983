import { Fragment, useCallback } from "react"
import { InviewTrigger, RotateBox } from "shared/components/anime"
import { useProjects, useSearchPresets } from "../api"

import { Dropdown } from "shared/components/form"
import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { ProjectCard } from "../components/project-card"
import { chunk } from "lodash"
import { cx } from "shared/helpers"
import { declOfNum } from "shared/helpers"
import { useFilter } from "../store"

export function Projects() {
  const filter = useFilter(state => state.filter)
  const setFilterParam = useFilter(state => state.setFilterParam)

  const resetKey = useCallback(key => () => setFilterParam(key, null), [setFilterParam])
  const filterBy = useCallback(key => val => setFilterParam(key, val), [setFilterParam])

  const { data: presets } = useSearchPresets({
    config: {
      select: ({ data }) => ({
        years: data.years.map(makeOption),
        cities: data.cities.map(makeOption)
      })
    }
  })

  const { data: { data: projects, meta } = {} } = useProjects({
    config: {
      select: applyFilter(filter)
    }
  })

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <h1 className="h1">проекты</h1>
      </div>
      <div className="mt-[20px]">
        <div className="aspect-[288/162] md:aspect-[1286/465]">
          <img src={require("../assets/7.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[40px] grid gap-[20px] md:mt-[60px] md:grid-cols-10 2xl:mt-[100px]">
        <div className="hidden md:col-span-4 md:block">
          <div className="h3">
            <TotalFound count={projects?.length ?? 0} />
          </div>
        </div>
        <div className="relative z-10 md:col-span-3">
          {presets?.cities && (
            <Dropdown
              placeholder="Все города"
              resetPlaceholder="Все города"
              options={presets.cities}
              selected={filter.city}
              onChange={filterBy("city")}
              onReset={resetKey("city")}
            />
          )}
        </div>
        <div className="relative z-0 md:z-10 md:col-span-3">
          {presets?.years && (
            <Dropdown
              placeholder="Все года"
              resetPlaceholder="Все года"
              options={presets.years}
              selected={filter.year}
              onChange={filterBy("year")}
              onReset={resetKey("year")}
            />
          )}
        </div>
      </div>
      <div className="h3 mt-[20px] md:hidden">
        <TotalFound count={projects?.length ?? 0} />
      </div>
      {projects && (
        <div className="mt-[60px] md:mt-[80px]">
          <div className="grid-cols-10 gap-[20px] md:grid">
            <div className="col-span-9 col-start-2">
              {chunk(projects, PER_ROW_COUNT).map((row, index) => (
                <div
                  key={index}
                  className={cx(
                    "grid grid-cols-1 gap-y-[40px] gap-x-[20px] md:grid-cols-3 md:gap-y-[0px]",
                    {
                      "mt-[40px] md:mt-[80px] 2xl:mt-[100px]": index > 0
                    }
                  )}
                >
                  {row.map((project, index, arr) => (
                    <Fragment key={index}>
                      {index === 0 &&
                        [...Array(PER_ROW_COUNT - arr.length)].map((_, index) => (
                          <div key={index} className="hidden md:block" />
                        ))}
                      <Link to={`/projects/${project.id}`} className="block">
                        <ProjectCard
                          name={project.name}
                          address={project.city}
                          picture={project.pictureUrl}
                          year={project.year}
                        />
                      </Link>
                    </Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="mt-[40px] text-right md:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link to="/cooperation" className="btn-b no-underline">
              сотрудничество
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </div>
  )
}

const PER_ROW_COUNT = 3

const makeOption = name => ({ id: name, name })

const applyFilter = filter => projects => ({
  meta: projects.meta,
  data: projects.data.filter(byYear(filter.year)).filter(byCity(filter.city))
})

const byCity = city => project => {
  if (city === null) return true
  return project.city === city.name
}

const byYear = year => project => {
  if (year === null) return true
  return project.year === year.name.toString()
}

const TotalFound = ({ count }) => {
  const prefix = declOfNum(count, ["Найден", "Найдено", "Найдено"])
  const suffix = declOfNum(count, ["проект", "проекта", "проектов"])
  return <span>{`${prefix} ${count} ${suffix}`}</span>
}

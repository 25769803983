import { Fieldset } from "./fieldset"
import { cx } from "shared/helpers"
import { useContext } from "react"

export function ControlLabel({ label, children, active }) {
  const isFieldsetActive = useContext(Fieldset.Context)
  return (
    <label className="flex items-center cursor-pointer group">
      {children}
      <div
        className={cx(
          "h3 flex-1 pl-[20px] pt-[0.25em] group-hover:opacity-100 whitespace-nowrap overflow-hidden text-ellipsis",
          {
            "opacity-100 text-white dark:text-gray-900": isFieldsetActive,
            "opacity-30": !isFieldsetActive && !active,
            "opacity-100": active
          }
        )}
      >
        {label}
      </div>
    </label>
  )
}

export function Progress({ id = "", total }) {
  return (
    <>
      <div className="h-[2px] bg-gray-900 opacity-50 dark:bg-[#BEBEBE]" />
      <div id={`${id}-scrollbar`} />
      <div className="mt-[8px] flex dark:text-white md:mt-[10px]">
        <div className="num flex-1 md:text-base">01</div>
        <div className="num">{String(total).padStart(2, 0)}</div>
      </div>
    </>
  )
}

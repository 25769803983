import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { cx } from "shared/helpers"

export function AchievementList({ achievements }) {
  return (
    <>
      {achievements.map((achievement, index) => (
        <div key={achievement.id} className={cx("h2", { "mt-[30px]": index > 0 })}>
          <HoverTrigger className="inline">
            <Underline>
              <Link to={`/achievements/${achievement.id}`}>{achievement.name}</Link>
            </Underline>
          </HoverTrigger>
        </div>
      ))}
    </>
  )
}

import { useMutation, useQuery } from "react-query"

import { http } from "shared/http"
import { queryClient } from "shared/react-query"

export { makeProposal, useTenders, useTender, useMakeProposal }

async function getTender(id) {
  const resp = await http(`/tenders/${id}`)
  return resp.data
}

async function getTenders(query = {}) {
  const resp = await http("/tenders", {
    params: {
      statusId: query.statusId,
      sphereId: query.sphereId,
      limit: query.limit
    }
  })
  return resp.data
}

function makeProposal({ tenderId, proposal }) {
  return http.post(`/tenders/${tenderId}`, proposal)
}

function useTenders({ query, config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["tenders", query],
    queryFn: () => getTenders(query)
  })
}

function useTender({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["tender", id],
    queryFn: () => getTender(id),
    useErrorBoundary: true
  })
}

function useMakeProposal({ tenderId, config }) {
  return useMutation({
    onSuccess: () => {
      queryClient.refetchQueries(["tender", tenderId])
    },
    ...config,
    mutationFn: proposal => makeProposal({ tenderId, proposal })
  })
}

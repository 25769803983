import create from "zustand"

export const useFilter = create(set => ({
  filter: {
    city: null,
    year: null
  },
  setFilterParam: (key, val) =>
    set(state => ({
      ...state,
      filter: {
        ...state.filter,
        [key]: val
      }
    }))
}))

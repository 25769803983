import create from "zustand"
import { persist } from "zustand/middleware"

export const useAuthStore = create(
  persist(
    (set, get) => ({
      token: null,
      authorize: token => set({ token }),
      logout: () => set({ token: null }),
      isAuthorized: () => get().token !== null
    }),
    {
      name: "auth-store",
      getStorage: () => window.localStorage
    }
  )
)

export const logout = () => {
  useAuthStore.setState({ token: null })
}

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useSpheres }

function getSpheres() {
  return http.get("/data/spheres")
}

function useSpheres({ config } = {}) {
  return useQuery({
    select: data => data.data,
    ...config,
    queryKey: ["data-spheres"],
    queryFn: getSpheres
  })
}

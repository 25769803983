import { http } from "shared/http"
import { useQuery } from "react-query"

export { useStories, useStory }

function getStories() {
  return http("/history")
}

function getStory(id) {
  return http(`/history/${id}`)
}

function useStories({ config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["history"],
    queryFn: getStories
  })
}

function useStory({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["history", id],
    queryFn: () => getStory(id),
    useErrorBoundary: true
  })
}

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useBanners }

function getBanners() {
  return http("/banners")
}

function useBanners({ config } = {}) {
  return useQuery({
    select: data => data.data,
    ...config,
    queryKey: ["banners"],
    queryFn: getBanners
  })
}

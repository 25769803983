import { cx } from "shared/helpers"
import { motion } from "framer-motion"

export function ScaleBox({ children, className }) {
  return (
    <div className={cx("relative h-full w-full overflow-hidden", className)}>
      <motion.div variants={slideMotion} transition={{ duration: 1.5, ease: "easeOut" }} className="h-full w-full">
        {children}
      </motion.div>
      <motion.div
        variants={filterMotion}
        transition={{ duration: 0.5 }}
        className="pointer-events-none absolute top-0 left-0 h-full w-full bg-black/20"
      />
    </div>
  )
}

const slideMotion = {
  initial: {
    scale: 1
  },
  animate: {
    scale: 1.05
  }
}

const filterMotion = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  }
}

import { HoverTrigger, Underline } from "shared/components/anime"
import { memo, useState } from "react"

import { ReactComponent as CloseAlt } from "../assets/close-alt.svg"
import { FooterMenu } from "./footer-menu"
import { Itef } from "shared/components/itef"
import { Link } from "react-router-dom"
import { ReactComponent as LogoSm } from "../assets/logo-sm.svg"

export const Footer = memo(function Footer() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  return (
    <>
      <FooterMenu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} />
      <footer className="container-x flex h-[56px] items-center bg-[#F5F5F5] dark:bg-[#121212] dark:text-white md:h-[75px] 2xl:h-[85px]">
        <div className="">
          <Link to="/" aria-label="Главная страница">
            <LogoSm className="h-[16px] w-[44px] 2xl:h-[25px] 2xl:w-[72px]" />
          </Link>
        </div>
        <div className="px-[10px] text-[5px] font-[400] tracking-[0.05em] opacity-[0.7] md:px-[30px] md:text-[9px] 2xl:px-[40px] 2xl:text-[10px]">
          Вся информация, содержащаяся на сайте, носит исключительно информационный характер и ни при каких условиях не
          является офертой, публичной офертой, предложением делать оферты
        </div>
        <div className="flex flex-1 justify-center md:justify-end">
          {menuIsOpen ? (
            <button onClick={() => setMenuIsOpen(false)} aria-label="Закрыть">
              <CloseAlt className="block h-[16px] w-[16px]" />
            </button>
          ) : (
            <HoverTrigger>
              <Underline reverse>
                <button className="btn no-underline" onClick={() => setMenuIsOpen(true)}>
                  Меню
                </button>
              </Underline>
            </HoverTrigger>
          )}
        </div>
        <div className="relative h-[16px] w-[44px] md:w-[140px]">
          <div className="absolute top-0 right-0">
            <Itef />
          </div>
        </div>
      </footer>
    </>
  )
})

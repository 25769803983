import create from "zustand"

export const useProgressBar = create((set, get) => ({
  requestsCount: 0,
  isLoading: () => get().requestsCount > 0
}))

export const incRequest = () =>
  useProgressBar.setState(state => ({
    requestsCount: state.requestsCount + 1
  }))

export const decRequest = () =>
  useProgressBar.setState(state => ({
    requestsCount: state.requestsCount > 0 ? state.requestsCount - 1 : 0
  }))

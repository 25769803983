import { useSettings } from "./settings"

export { useContacts }

function useContacts(config) {
  return useSettings({
    select: data => ({
      phone: data.phone,
      email: data.email,
      address: data.addressOffice
    }),
    ...config
  })
}

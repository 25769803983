import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { useLogout } from "features/auth"

export function Navigation() {
  const logout = useLogout()
  return (
    <div className="grid gap-[15px] md:gap-[20px]">
      <HoverTrigger>
        <Underline reverse>
          <Link to="/cabinet/tenders" className="btn no-underline">
            посмотреть заказы
          </Link>
        </Underline>
      </HoverTrigger>
      <HoverTrigger>
        <Underline reverse>
          <span className="btn no-underline cursor-pointer" onClick={logout}>
            Выход
          </span>
        </Underline>
      </HoverTrigger>
    </div>
  )
}

import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"
import { Navigation } from "../componets/navigation"
import { PatternFormat } from "react-number-format"
import { Placeholder } from "shared/components/form"
import { Ul } from "../componets/ul"
import { useProfile } from "../api"
import { useSpheres } from "shared/api"

export function Profile() {
  const { data: spheres } = useSpheres()
  const { data: profile } = useProfile({
    config: {
      select: data => {
        const info = { ...data.info }
        const ids = (info.spheres || []).map(Number)
        info.spheres = (spheres || []).filter(s => ids.includes(s.id))
        return { ...data, info }
      }
    }
  })

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">профиль</h1>
      </div>
      <div className="md:hidden text-center mt-[30px]">
        <Navigation />
      </div>
      {profile && (
        <div className="mt-[20px] md:mt-[80px] 2xl:mt-[100px]">
          <div className="grid-cols-10 gap-[40px] md:grid">
            <div className="col-span-5 hidden md:block">
              <Navigation />
            </div>
            <div className="col-span-4">
              <div className="grid gap-[20px]">
                <Placeholder>{profile.name}</Placeholder>
                {profile.email && <Placeholder>{profile.email}</Placeholder>}
                <Placeholder>
                  {
                    <PatternFormat
                      value={profile.phone}
                      format="+7 (###) #######"
                      displayType="text"
                    />
                  }
                </Placeholder>
                {profile.info && (
                  <>
                    {profile.info.spheres.length > 0 && (
                      <Placeholder label="Направления">
                        <Ul options={profile.info.spheres} />
                      </Placeholder>
                    )}
                    {profile.info.description && (
                      <Placeholder label="Расскажите о себе">
                        {profile.info.description}
                      </Placeholder>
                    )}
                    {profile.info.portfolioLink && (
                      <Placeholder label="ссылка на портфолио">
                        {profile.info.portfolioLink}
                      </Placeholder>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="grid-cols-10 gap-[40px] md:grid mt-[40px] md:mt-[60px] md:text-right 2xl:mt-[100px]">
            <div className="col-start-6 col-end-11">
              <div className="text-right">
                <InviewTrigger>
                  <RotateBox>
                    <Link to="/cabinet/profile/edit" className="btn-b no-underline">
                      Редактировать
                    </Link>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import { ErrorMessage } from "./error-message"
import { createContext } from "react"
import { cx } from "shared/helpers"

export function Fieldset({ label, active, children, errorMessage }) {
  return (
    <Context.Provider value={active}>
      <div
        className={cx("border-2 border-gray-900 p-[20px] md:p-[30px] 2xl:p-[40px]", {
          "dark:bg-white": active,
          "bg-gray-900": active
        })}
      >
        <div
          className={cx("h2", {
            "text-white": active,
            "opacity-100": active,
            "opacity-30": !active,
            "dark:text-black": active
          })}
        >
          {label}
        </div>
        {children}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Context.Provider>
  )
}

const Context = createContext(false)
Fieldset.Context = Context

import { HoverTrigger, Underline } from "shared/components/anime"
import { memo, useMemo, useRef, useState } from "react"

import { HeaderMenu } from "./header-menu"
import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as LogoSm } from "../assets/logo-sm.svg"
import { createPortal } from "react-dom"
import { motion } from "framer-motion"
import { useAuthStore } from "features/auth"
import { useLocation } from "react-router-dom"
import { useProfile } from "features/profile"
import { useThemeStore } from "../store"

export const Header = memo(function Header({ onOpen, onClose }) {
  const $busy = useRef(false)

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const closeMenu = () => setMenuIsOpen(false)
  const toggleMenu = () => setMenuIsOpen(o => !o)

  const { data: profile } = useProfile()
  const isAuthorized = useAuthStore(state => state.isAuthorized())

  const toggleTheme = useThemeStore(state => state.toggleTheme)
  const safeToggleMenu = () => {
    if (!$busy.current) {
      toggleMenu()
    }
  }

  const location = useLocation()
  const isMainRoute = location.pathname === "/"
  const profileHintIsVisible = [!menuIsOpen, isAuthorized, profile !== void 0].every(Boolean)

  const animationPipe = useMemo(
    () => ({
      "before-open": () => {
        $busy.current = true
      },
      "before-close": () => {
        $busy.current = true
        onClose()
      },
      "open-complete": () => {
        $busy.current = false
        onOpen()
      },
      "close-complete": () => {
        $busy.current = false
      }
    }),
    [onClose, onOpen]
  )

  return (
    <>
      <div className="sticky top-0 z-30">
        <header className="container-x bg-white dark:bg-black">
          <div className="grid h-[56px] grid-cols-3 md:h-[110px] 2xl:h-[125px]">
            <div className="pt-[20px] md:pt-[30px]">
              <div className="hidden md:block">
                <HomeLink active={!isMainRoute} onClick={closeMenu} />
              </div>
              <div className="md:hidden">
                <HomeLinkSm active={!isMainRoute} onClick={closeMenu} />
              </div>
            </div>
            <div className="flex justify-center pt-[0px] md:pt-[23px] 2xl:pt-[30px]">
              <div className="-mt-[2px] md:hidden">
                <div className="scale-50">
                  <Burger active={menuIsOpen} onClick={safeToggleMenu} />
                </div>
              </div>
              <div className="hidden md:block">
                <Burger active={menuIsOpen} onClick={safeToggleMenu} />
              </div>
            </div>
            <div className="flex justify-end">
              {profileHintIsVisible && (
                <div className="hidden md:block">
                  <div className="h3 pt-[46px] 2xl:pt-[53px] pr-[60px] 2xl:pr-[100px]">
                    <span className="text-[#C4C4C4]">
                      <HoverTrigger>
                        <Underline>
                          <Link to="/cabinet" className="btn no-underline" onClick={closeMenu}>
                            {profile.name}
                          </Link>
                        </Underline>
                      </HoverTrigger>
                    </span>
                  </div>
                </div>
              )}
              <div className="pt-[20px] md:pt-[48px] 2xl:pt-[54px]">
                <button onClick={toggleTheme} aria-label="Сменить тему" className="block">
                  <div className="dark:hidden">
                    <img
                      src={require("../assets/switch-light.svg").default}
                      className="h-[13px] w-[13px] md:h-[15px] md:w-[15px] 2xl:h-[17px] 2xl:w-[17px]"
                      alt=""
                    />
                  </div>
                  <div className="hidden dark:block">
                    <img
                      src={require("../assets/switch-dark.svg").default}
                      className="h-[13px] w-[13px] md:h-[15px] md:w-[15px] 2xl:h-[17px] 2xl:w-[17px]"
                      alt=""
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
      {createPortal(
        // prettier-ignore
        <HeaderMenu
          isOpen={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          onAnimationState={state => {
            if (animationPipe[state]) {
              animationPipe[state]()
            }
          }}
        />,
        document.body
      )}
    </>
  )
})

function Burger({ active, onClick }) {
  return (
    <button className="block h-[60px] w-[60px]" aria-label="Меню" onClick={onClick}>
      <motion.div
        className="h-[3px] w-[60px] bg-gray-900 dark:bg-white"
        animate={{ rotate: active ? 45 : 0, y: active ? 0 : -3 }}
        transition={{ duration: 0.25 }}
        initial={false}
      />
      <motion.div
        className="-mt-[3px] h-[3px] w-[60px] bg-gray-900 dark:bg-white"
        animate={{ rotate: active ? -45 : 0, y: active ? 0 : 3 }}
        transition={{ duration: 0.25 }}
        initial={false}
      />
    </button>
  )
}

function HomeLink({ active, onClick }) {
  return active ? (
    <Link to="/" className="inline-flex" onClick={onClick}>
      <Logo className="h-[50px] w-[241px] text-gray-900 dark:text-white 2xl:h-[65px] 2xl:w-[315px]" />
    </Link>
  ) : (
    <Logo className="h-[50px] w-[241px] text-gray-900 dark:text-white 2xl:h-[65px] 2xl:w-[315px]" />
  )
}

function HomeLinkSm({ active, onClick }) {
  return active ? (
    <Link to="/" className="inline-flex" onClick={onClick}>
      <LogoSm className="h-[16px] w-[44px] text-gray-900 dark:text-white" />
    </Link>
  ) : (
    <LogoSm className="h-[16px] w-[44px] text-gray-900 dark:text-white" />
  )
}

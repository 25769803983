import { HoverTrigger, Underline } from "shared/components/anime"
import { useRef, useState } from "react"

import { ReactComponent as ArrowRight } from "../assets/dd-right.svg"
import { cx } from "shared/helpers"
import { motion } from "framer-motion"
import { useOnClickOutside } from "shared/hooks"

export function Dropdown({
  options,
  selected,
  placeholder,
  resetPlaceholder,
  idKey = "id",
  nameKey = "name",
  onReset,
  onChange
}) {
  const $dropdown = useRef()
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside($dropdown, () => setIsOpen(false))

  const toggleIsOpen = () => setIsOpen(o => !o)

  const setOption = option => {
    setIsOpen(false)
    onChange(option)
  }

  const reset = () => {
    setIsOpen(false)
    onReset()
  }

  const hasVal = selected !== null
  const label = hasVal ? selected[nameKey] : placeholder

  return (
    <div className="relative z-10" ref={$dropdown}>
      <motion.div
        title={label}
        initial="initial"
        whileHover={isOpen ? "open" : "hover"}
        onClick={toggleIsOpen}
        className={cx(
          "h2 flex h-[60px] w-full cursor-pointer border-2 border-gray-900 px-[20px] outline-none placeholder:text-gray-900 placeholder:opacity-30 hover:bg-gray-900 hover:text-white dark:border-white dark:placeholder:text-white dark:hover:bg-white dark:hover:text-gray-900 md:h-[90px] md:px-[30px] 2xl:h-[100px]",
          {
            "text-white": hasVal,
            "bg-gray-900": hasVal,
            "dark:bg-white": hasVal,
            "dark:text-gray-900": hasVal,
            "dark:bg-transparent": !hasVal
          }
        )}
      >
        <div className="grid flex-1 grid-cols-[1fr,auto] items-center">
          <div
            className={cx("overflow-hidden text-ellipsis whitespace-nowrap", {
              "opacity-100": hasVal,
              "opacity-30": !hasVal
            })}
          >
            {label}
          </div>
          <div className="flex w-[40px] justify-end md:w-[70px]">
            <motion.div
              variants={arrowMotion}
              animate={{ rotate: isOpen ? 90 : 0 }}
              transition={{ duration: 0.2 }}
              className="origin-center-right"
            >
              <ArrowRight className="block w-[40px] md:w-[70px]" />
            </motion.div>
          </div>
        </div>
      </motion.div>
      {isOpen && (
        <div className="absolute left-0 right-0 top-[100%] grid gap-y-[20px] border-2 border-t-0 border-gray-900 bg-white px-[20px] py-[20px] dark:border-white dark:bg-black md:px-[40px] md:py-[30px] max-h-80 overflow-y-auto">
          {resetPlaceholder && (
            <div className="h2 inline cursor-pointer" onClick={reset}>
              <HoverTrigger>
                <Underline>{resetPlaceholder}</Underline>
              </HoverTrigger>
            </div>
          )}
          {options.map(option => (
            <div key={option[idKey]}>
              <div className="h2 inline cursor-pointer" onClick={() => setOption(option)}>
                <HoverTrigger>
                  <Underline>{option[nameKey]}</Underline>
                </HoverTrigger>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const arrowMotion = {
  initial: { rotate: 0 },
  hover: { rotate: -90 }
}

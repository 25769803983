import "swiper/css"
import "swiper/css/effect-fade"

import { Autoplay, EffectFade } from "swiper"
import { Children, cloneElement } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import { Controller } from "./controller"
import { motion } from "framer-motion"

export function Slider({ children, options, id }) {
  return (
    <motion.div initial="start" animate="start" whileHover="hover">
      <Swiper
        modules={[EffectFade, Autoplay]}
        scrollbar={{ draggable: true, el: `#${id}-scrollbar` }}
        onImagesReady={swiper => {
          if (swiper) {
            swiper.update()
            swiper.scrollbar.init()
            swiper.slideTo(0, 0, false)
          }
        }}
        {...options}
      >
        {Children.map(children, child => (
          <SwiperSlide>{cloneElement(child)}</SwiperSlide>
        ))}
        <motion.div
          variants={controlMotion}
          transition={{ duration: 0.5 }}
          className="pointer-events-none absolute top-0 right-0 bottom-0 left-0 z-10 hidden md:block"
        >
          <Controller />
        </motion.div>
      </Swiper>
    </motion.div>
  )
}

const controlMotion = {
  start: {
    opacity: 0
  },
  hover: {
    opacity: 1
  }
}

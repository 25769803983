import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"
import { useEffect, useRef, useState } from "react"

import { Link } from "react-router-dom"
import { useAuthStore } from "features/auth"

export function Cooperation() {
  const $pointer = useRef()
  const [offset, setOffset] = useState()

  const isAuthorized = useAuthStore(state => state.isAuthorized())

  useEffect(() => {
    const { top } = $pointer.current.getBoundingClientRect()
    setOffset(top + window.scrollY)
  }, [])

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <div className="md:flex md:items-center">
          <h1 className="h1 md:flex-1">сотрудничество</h1>
          {!isAuthorized && (
            <div className="hidden md:block">
              <HoverTrigger className="inline-flex">
                <Underline reverse>
                  <Link className="btn no-underline" to="/auth/sign-in">
                    вход
                  </Link>
                </Underline>
              </HoverTrigger>
            </div>
          )}
        </div>
      </div>
      {!isAuthorized && (
        <div className="mt-[20px] text-right md:hidden">
          <HoverTrigger className="inline-flex">
            <Underline reverse>
              <Link className="btn no-underline" to="/auth/sign-in">
                вход
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      )}
      <div className="grid-cols-10 md:mt-[80px] md:grid md:gap-[20px] 2xl:mt-[120px]">
        <div className="col-span-3 col-start-2 hidden md:block">
          <div ref={$pointer} className="sticky" style={{ top: offset }}>
            <Ludi />
          </div>
        </div>
        <div className="col-span-5 col-start-6">
          <div className="mt-[20px] mb-[40px] md:hidden">
            <Ludi />
          </div>
          <div className="h2">Мы всегда открыты к работе с талантливыми и амбициозными людьми.</div>
          <div className="h2 mt-[20px] md:mt-[60px] 2xl:md:mt-[100px]">
            Хотите стать частью нашей дружной{" "}
            <HoverTrigger className="inline">
              <Underline reverse>
                <Link className="no-underline" to="/team">
                  КОМАНДЫ
                </Link>
              </Underline>
            </HoverTrigger>{" "}
            и участвовать в реализации масштабных{" "}
            <HoverTrigger className="inline">
              <Underline reverse>
                <Link className="no-underline" to="/projects">
                  ПРОЕКТОВ
                </Link>
              </Underline>
            </HoverTrigger>{" "}
            для жилой и коммерческой недвижимости?
          </div>
          <div className="h1 mt-[60px] md:mt-[100px] 2xl:mt-[200px]">РАССКАЖИТЕ О СВОЕМ ОПЫТЕ И СПЕЦИАЛИЗАЦИИ</div>
          <div className="h2 mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
            Максимально полно заполните информацию о себе в форме регистрации. Мы ознакомимся с вашей анкетой и
            оповестим Вас о своих предложениях.
          </div>
          <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
            <InviewTrigger>
              <RotateBox>
                <Link className="btn-b no-underline" to="/auth/sign-up">
                  РЕГИСТРАЦИЯ
                </Link>
              </RotateBox>
            </InviewTrigger>
          </div>
          <div className="mt-[20px] mb-[40px] pl-[30px] md:mt-[80px] md:mb-[80px] 2xl:mt-[100px] 2xl:mb-[100px]">
            <div className="dark:hidden">
              <img loading="lazy" src={require("../assets/4.svg").default} className="h-[60px] md:h-[80px]" alt="" />
            </div>
            <div className="hidden dark:block">
              <img loading="lazy" src={require("../assets/3.svg").default} className="h-[60px] md:h-[80px]" alt="" />
            </div>
          </div>
          <div className="h1">ПОЛУЧИТЕ ДОСТУП К НАШИМ ПРОЕКТАМ</div>
          <div className="h2 mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
            Для зарегистрированных членов нашей команды на сайте доступны материалы о наших проектах, в которых Вы
            можете принять участие.
          </div>
          {!isAuthorized && (
            <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
              <InviewTrigger>
                <RotateBox>
                  <Link className="btn-b no-underline" to="/auth/sign-in">
                    вход
                  </Link>
                </RotateBox>
              </InviewTrigger>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Ludi() {
  return (
    <div className="aspect-[288/188]">
      <div className="dark:hidden">
        <img src={require("../assets/2.svg").default} className="img-cover" alt="" />
      </div>
      <div className="hidden dark:block">
        <img src={require("../assets/1.svg").default} className="img-cover" alt="" />
      </div>
    </div>
  )
}

import { EditProfile, Profile } from "features/profile"
import { Tender, Tenders } from "features/tenders"

import { ChangePassword } from "features/profile"
import { Layout } from "features/misc"
import { NotFound } from "shared/components/not-found"
import { RequireAuth } from "features/auth"

export const privateRoutes = [
  {
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "cabinet",
        element: <RequireAuth />,
        children: [
          {
            index: true,
            element: <Profile />
          },
          {
            path: "profile/edit",
            element: <EditProfile />
          },
          {
            path: "tenders",
            element: <Tenders />
          },
          {
            path: "tenders/:id",
            element: <Tender />
          },
          {
            path: "change-password",
            element: <ChangePassword />
          }
        ]
      }
    ]
  }
]

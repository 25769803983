import { PatternFormat } from "react-number-format"
import { TextField } from "shared/components/form"
import { forwardRef } from "react"
import { useState } from "react"

export const DateField = forwardRef((props, ref) => {
  const [inFocus, setInFocus] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const prevPlacehoder = props.placeholder
  const placeholder = isHover || inFocus ? "дд.мм.гггг" : prevPlacehoder

  return (
    <PatternFormat
      {...props}
      getInputRef={ref}
      format="##.##.####"
      customInput={TextField}
      placeholder={placeholder}
      onFocus={() => setInFocus(true)}
      onBlur={() => setInFocus(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  )
})

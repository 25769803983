import { Form, PasswordField } from "shared/components/form"
import { InviewTrigger, RotateBox } from "shared/components/anime"

import { ChangePasswordOk } from "../componets/change-password-ok"
import { makeValidation } from "shared/helpers"
import { useUpdateProfile } from "../api"

export function ChangePassword() {
  const { mutate: updateProfile, isSuccess, isLoading } = useUpdateProfile()
  const submitForm = (payload, { setError }) => {
    updateProfile(payload, {
      onError: error => makeValidation(error, setError)
    })
  }

  if (isSuccess) {
    return <ChangePasswordOk />
  }

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <Form onSubmit={submitForm}>
          {({ register, formState }) => (
            <>
              <div className="h1">Новый пароль</div>
              <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
                <PasswordField
                  placeholder="старый пароль"
                  autoComplete="new-password"
                  errorMessage={formState.errors.passwordOld?.message}
                  {...register("passwordOld")}
                />
                <div className="mt-[10px] md:mt-[20px]">
                  <PasswordField
                    placeholder="новый пароль"
                    autoComplete="new-password"
                    errorMessage={formState.errors.password?.message}
                    {...register("password")}
                  />
                </div>
                <div className="mt-[10px] md:mt-[20px]">
                  <PasswordField
                    placeholder="повторите пароль"
                    autoComplete="new-password"
                    errorMessage={formState.errors.passwordConfirmation?.message}
                    {...register("passwordConfirmation")}
                  />
                </div>
              </div>
              <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
                <InviewTrigger>
                  <RotateBox>
                    <button type="submit" disabled={isLoading} className="btn-b no-underline">
                      отправить
                    </button>
                  </RotateBox>
                </InviewTrigger>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

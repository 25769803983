import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"

export function Contacts({ email }) {
  return (
    <div>
      <a className="h2" href={`mailto:${email}`}>
        {email}
      </a>
      <div className="mt-[10px]">
        <InviewTrigger>
          <RotateBox>
            <Link to="/cooperation" className="btn no-underline">
              присоединяйтесь к нам
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </div>
  )
}

import { Children } from "react"
import { Progress } from "./progress"
import { Slider } from "./slider"

export function Swiper({ children, options, id }) {
  const totalSlides = Children.toArray(children).length
  return (
    <div className="global-swiper relative">
      <Slider options={options} id={id}>
        {children}
      </Slider>
      <div className="mt-5">
        <Progress id={id} total={totalSlides} />
      </div>
    </div>
  )
}

import { Heading, Text } from "./typo"

import { Fragment } from "react"
import { Gallery } from "./gallery"
import { createContext } from "react"

const ProjectContext = createContext({ projectId: null })

export { ContentBuilder, ProjectContext }

function ContentBuilder({ blocks, projectId }) {
  return (
    <ProjectContext.Provider value={{ projectId }}>
      {blocks.map((block, index) => {
        const Component = components.get(block.type)
        return Component ? (
          <Fragment key={index}>
            <Component {...block} />
          </Fragment>
        ) : null
      })}
    </ProjectContext.Provider>
  )
}

const components = new Map([
  ["gallery", Gallery],
  ["heading", Heading],
  ["text", Text]
])

import { AnimatePresence, motion } from "framer-motion"

import { ReactComponent as ItefIcon } from "../assets/itef.svg"
import { ReactComponent as UgolIcon } from "../assets/ugol.svg"
import { useState } from "react"

export function Itef() {
  const [open, setOpen] = useState(false)

  const onEnter = () => setOpen(true)
  const onLeave = () => setOpen(false)

  return (
    <a target="_blank" href="https://itef.ru/" rel="noreferrer" aria-label="itef.ru">
      <div
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        className="relative flex h-[26px] w-[100px] justify-end overflow-hidden"
      >
        <UgolIcon className="h-[12px] w-[12px]" />
        <AnimatePresence>
          {open && (
            <motion.div
              exit={{ left: 100 }}
              initial={{ left: 100 }}
              animate={{ left: 5 }}
              className="pointer-events-none absolute top-0 right-0"
            >
              <ItefIcon className="h-[19px] w-[89px]" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </a>
  )
}

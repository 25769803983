import create from "zustand"
import { nanoid } from "nanoid"

export const useNotifications = create(set => ({
  notifications: [],
  addNotification: ({ message, type }) =>
    set(state => ({
      notifications: state.notifications.concat(createNotification(message, type))
    })),
  removeNotification: id =>
    set(state => ({
      notifications: state.notifications.filter(n => n.id !== id)
    }))
}))

export const addNotification = ({ message, type }) => {
  const notification = createNotification(message, type)
  useNotifications.setState(state => ({
    notifications: [...state.notifications, notification]
  }))
}

const createNotification = (message, type = "info") => ({
  id: nanoid(),
  message,
  type
})

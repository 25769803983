import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "../assets/4.svg"

export function NotFound() {
  return (
    <div className="container-x container-y flex min-h-screen flex-1 items-center justify-center">
      <div className="grid grid-cols-4 md:hidden">
        <div className="col-span-2 col-start-2">
          <div className="aspect-[1/1]">{I1}</div>
        </div>
        <div className="col-span-1 col-start-1">
          <div className="aspect-[1/1]">{I2}</div>
        </div>
        <div className="col-span-1 col-start-3 row-start-3">
          <div className="aspect-[1/1]">{I3}</div>
        </div>
      </div>
      <div className="hidden grid-cols-10 gap-[20px] md:grid">
        <div className="col-span-2 flex flex-col justify-end">
          <div className="aspect-[1/1]">{I3}</div>
          <div className="aspect-[1/1] w-[calc(50%-10px)]" />
        </div>
        <div className="col-span-1 flex items-end">
          <div className="aspect-[1/1]">{I2}</div>
        </div>
        <div className="col-span-2 flex items-end">
          <div className="aspect-[1/1]">{I1}</div>
        </div>
        <div className="col-span-5 flex items-end justify-end">
          <div className="whitespace-nowrap">
            <Logo className="block w-full dark:text-white" />
            <div className="mt-[60px] text-right 2xl:mt-[130px]">
              <HomeLink />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px] md:hidden">
        <div>
          <Logo className="block h-full w-full object-cover dark:text-white" />
        </div>
        <div className="mt-[26px] w-[100%] text-right">
          <HomeLink />
        </div>
      </div>
    </div>
  )
}

const I1 = (
  <img
    loading="lazy"
    src={require("../assets/3.jpg")}
    className="block h-full w-full object-cover"
    alt=""
  />
)
const I2 = (
  <img
    loading="lazy"
    src={require("../assets/2.jpg")}
    className="block h-full w-full object-cover"
    alt=""
  />
)
const I3 = (
  <img
    loading="lazy"
    src={require("../assets/1.jpg")}
    className="block h-full w-full object-cover"
    alt=""
  />
)
const HomeLink = () => (
  <Link to="/" className="btn-b dark:text-white">
    на главную
  </Link>
)

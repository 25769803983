import { ExtraNewsCard } from "../components/extra-news-card"
import { MetaTags } from "shared/components/seo"
import { NewsList } from "../components/news-list"
import { cx } from "shared/helpers"
import { useNews } from "../api"

export function News() {
  const { data: { data: news, meta } = {} } = useNews()

  const allNews = news ?? []
  const [headNews] = allNews
  const hasExtraNews = headNews !== void 0 && headNews.pictureUrl === null
  const restNews = hasExtraNews ? allNews.slice(1, allNews.length) : allNews

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">новости</h1>
      </div>
      {hasExtraNews && (
        <div className="mt-[20px] mb-[40px] cursor-pointer md:mt-[100px] md:mb-[20px]">
          <ExtraNewsCard
            id={headNews.id}
            datetime={headNews.publishedAt}
            picture={require("../assets/2.jpg")}
            name={headNews.name}
            lead={headNews.annotation}
          />
        </div>
      )}
      <div className={cx({ "mt-[20px] md:mt-[100px]": !hasExtraNews })}>
        <NewsList news={restNews} />
      </div>
    </div>
  )
}

import { forwardRef, useEffect, useState } from "react"

import { ErrorMessage } from "./error-message"
import { cx } from "shared/helpers"

export const TextField = forwardRef((props, ref) => {
  const { value, defaultValue, errorMessage, onChange = () => {}, ...rest } = props

  const [localValue, setLocalValue] = useState()
  const hasValue = !!localValue

  const handleChange = ev => {
    setLocalValue(ev.target.value)
    onChange(ev)
  }

  useEffect(() => {
    setLocalValue(value || defaultValue)
  }, [value, defaultValue])

  return (
    <div>
      <input
        {...rest}
        ref={ref}
        type="text"
        className={cx(
          "h2 h-[60px] w-full border-2 border-gray-900 px-[20px] outline-none placeholder:text-gray-900 placeholder:opacity-30 hover:bg-gray-900 hover:placeholder:text-white dark:border-white dark:placeholder:text-white dark:hover:bg-white dark:hover:placeholder:text-gray-900 md:h-[90px] md:px-[30px] 2xl:h-[100px] dark:hover:text-gray-900 hover:text-white normal-case placeholder:uppercase",
          {
            "bg-gray-900": hasValue,
            "dark:bg-white": hasValue,
            "text-white": hasValue,
            "dark:text-gray-900": hasValue,
            "bg-white": !hasValue,
            "dark:bg-transparent": !hasValue
          }
        )}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
})

import { forwardRef, useEffect, useState } from "react"

import { ErrorMessage } from "./error-message"
import { cx } from "shared/helpers"

export const TextareaField = forwardRef((props, ref) => {
  const { value, defaultValue, label, errorMessage, onChange = () => {}, ...rest } = props

  const [localValue, setLocalValue] = useState()
  const hasValue = !!localValue

  const handleChange = ev => {
    setLocalValue(ev.target.value)
    onChange(ev)
  }

  useEffect(() => {
    setLocalValue(value || defaultValue)
  }, [value, defaultValue])

  return (
    <>
      <div
        className={cx(
          "group border-2 border-gray-900 p-[20px] md:p-[30px] 2xl:p-[40px] hover:bg-gray-900 hover:dark:bg-white",
          {
            "bg-gray-900": hasValue,
            "dark:bg-white": hasValue,
            "text-white": hasValue,
            "dark:text-gray-900": hasValue,
            "bg-white": !hasValue,
            "dark:bg-transparent": !hasValue
          }
        )}
      >
        <div
          className={cx("h2", {
            "text-gray-900/30 group-hover:text-white/30 dark:text-white/30 dark:group-hover:text-gray-900/30":
              !hasValue,
            "text-white dark:text-gray-900": hasValue
          })}
        >
          {label}
        </div>
        <div className="mt-[10px] md:mt-[20px]">
          <textarea
            rows="10"
            {...rest}
            ref={ref}
            className="h3 w-full resize-none bg-transparent outline-none placeholder:opacity-0 group-hover:placeholder:opacity-30 placeholder:text-white dark:placeholder:text-gray-900 appearance-none dark:hover:text-gray-900 hover:text-white normal-case placeholder:uppercase"
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
          />
        </div>
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  )
})

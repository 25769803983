import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "../assets/12.svg"

export function Team() {
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">команда</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/team">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="md:w-[calc(50%+20px)]">
        <div className="mt-[20px] grid grid-cols-4 gap-[16px] md:gap-[16px]">
          <div className="col-span-3">
            <div className="aspect-[1/1]">
              <img loading="lazy" src={require("../assets/10.jpg")} className="img-cover" alt="" />
            </div>
          </div>
          <div className="col-span-1">
            <div className="aspect-[1/1]">
              <img loading="lazy" src={require("../assets/11.jpg")} className="img-cover" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px] md:mt-[100px] 2xl:mt-[200px]">
        <div className="px-[40px] md:m-auto md:w-1/2 md:px-[0px]">
          <Logo className="img-cover" />
        </div>
      </div>
      <div className="mt-[20px] md:mt-[60px] md:pl-[calc(50%+20px)] 2xl:mt-[100px]">
        <div className="h2 md:h1">
          В ПЕРВУЮ ОЧЕРЕДЬ ЛЮДИ, СОВЕРШЕН&shy;СТВУЮЩИЕ МИР ВОКРУГ БЛАГОДАРЯ СВОИМ НАВЫКАМ НА СТЫКЕ НАУКИ И ИСКУССТВА.
        </div>
      </div>
      <div className="mt-[40px] md:mt-[60px] md:pl-[calc(50%+20px)] 2xl:mt-[160px]">
        <div className="txt">
          Уже сегодня членами команды A|U являются высококвалифицированные специалисты, способные решить любую задачу
          повышенной сложности. Регулярное повышение квалификации, командобразующие мероприятия, формирование кадрового
          резерва линейных руководителей, способствует мотивации наших сотрудников на новые подвиги.
        </div>
      </div>
      <div className="mt-[40px] text-right md:mt-[60px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link className="btn-b no-underline" to="/team">
              подробнее о команде
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </>
  )
}

import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"

export function SuggestSignUp() {
  return (
    <div>
      <div className="h2">
        Хотите стать
        <br />
        нашим партнером?
      </div>
      <div className="mt-[10px]">
        <HoverTrigger>
          <Underline reverse>
            <Link to="/auth/sign-up" className="btn no-underline">
              регистрация
            </Link>
          </Underline>
        </HoverTrigger>
      </div>
    </div>
  )
}

import { http } from "shared/http"
import { useMutation } from "react-query"

export { useSignUpUser, useSignInUser, useForgotPassword, useResetPassword }

function signUpUser(user) {
  return http.post("/auth/partners/register", user)
}

function signInUser({ login, password }) {
  return http.post("/auth/partners/login", {
    email: login,
    password
  })
}

function requestForgotPassword({ email }) {
  return http.post("/auth/password/reset", {
    email
  })
}

function confirmResetPassword({ hash, credentials }) {
  return http.post("/auth/password/update", {
    passwordConfirmation: credentials.passwordConfirmation,
    password: credentials.password,
    hash
  })
}

function useSignUpUser({ config } = {}) {
  return useMutation({
    ...config,
    mutationFn: signUpUser
  })
}

function useSignInUser({ config } = {}) {
  return useMutation({
    ...config,
    mutationFn: signInUser
  })
}

function useForgotPassword({ config } = {}) {
  return useMutation({
    ...config,
    mutationFn: requestForgotPassword
  })
}

function useResetPassword({ hash, config } = {}) {
  return useMutation({
    ...config,
    mutationFn: credentials => confirmResetPassword({ hash, credentials })
  })
}

import { HoverTrigger, Underline } from "shared/components/anime"

import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { NoIndex } from "shared/components/seo"
import { useCookieStore } from "../store"

export function CookieInformer() {
  const { menuIsOpen, closeMenu } = useCookieStore()
  return (
    <NoIndex>
      {menuIsOpen && (
        <div className="fixed left-0 right-0 bottom-0 z-30">
          <div className="bg-[#121212] p-[30px] text-white">
            <div className="flex">
              <div className="flex-1 text-[15px] leading-[160%]">
                Мы используем файлы cookie для улучшения сайта. Продолжая пользоваться сайтом, Вы подтверждаете свое
                согласие на использование cookie файлов и обработку персональных данных согласно{" "}
                <HoverTrigger className="inline uppercase">
                  <Underline reverse>
                    <span className="cursor-pointer">
                      <a
                        href={`${process.env.PUBLIC_URL}/docs/policy.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Политика обработки персональных данных
                      </a>
                    </span>
                  </Underline>
                </HoverTrigger>
              </div>
              <div className="pl-[40px]">
                <button onClick={closeMenu} aria-label="Закрыть">
                  <CloseIcon className="block h-[15px] w-[16px] md:h-[45px] md:w-[46px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </NoIndex>
  )
}

import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { useContacts } from "shared/api"

export function Contacts() {
  const { data: contacts } = useContacts()
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">Контакты</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/contacts">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="mt-[40px] md:mt-[120px]  md:pl-[calc(50%+20px)]">
        <div className="h3">пРИСОЕДИНЯЙТЕСЬ К НАМ</div>
        {contacts?.email && (
          <div className="mt-[10px] md:mt-[20px]">
            <a className="h2" href={`mailto:${contacts.email}`}>
              {contacts.email}
            </a>
          </div>
        )}
        {contacts?.phone && (
          <div className="mt-[40px] md:mt-[60px] 2xl:mt-[100px]">
            <a className="h1" href={`tel:${contacts.phone}`}>
              {contacts.phone}
            </a>
          </div>
        )}
      </div>
    </>
  )
}

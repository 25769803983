import { HoverTrigger, InviewTrigger, RotateBox, ScaleBox } from "shared/components/anime"
import { format, parseISO } from "date-fns"

import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { Swiper } from "shared/components/swiper"
import { useParams } from "react-router-dom"
import { useStory } from "../api"

export function Story() {
  const id = useParams().id
  const { data: { data: history, meta } = {} } = useStory({ id })

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      {history && (
        <>
          <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
            <h1 className="h1">{history.name}</h1>
          </div>
          <div className="mt-[20px] mb-[40px] md:mb-[60px] 2xl:mb-[100px]">
            {(() => {
              const files = history.files ?? []
              const [headFile] = files
              return (
                <>
                  {files.length === 0 && null}
                  {files.length === 1 && (
                    <HoverTrigger>
                      <ScaleBox className="h-[160px] md:h-[465px] 2xl:h-[670px]">
                        <img
                          loading="lazy"
                          src={headFile.url}
                          className="img-cover h-[160px] md:h-[465px] 2xl:h-[670px]"
                          alt=""
                        />
                      </ScaleBox>
                    </HoverTrigger>
                  )}
                  {files.length > 1 && (
                    <Swiper
                      id="history-swiper"
                      options={{ slidesPerView: "auto", spaceBetween: 8, cssMode: true }}
                    >
                      {files.map(file => (
                        <HoverTrigger key={file.hash}>
                          <ScaleBox className="h-[160px] md:h-[465px] 2xl:h-[670px]">
                            <img
                              loading="lazy"
                              src={file.url}
                              className="img-cover h-[160px] w-auto md:h-[465px] 2xl:h-[670px]"
                              alt=""
                            />
                          </ScaleBox>
                        </HoverTrigger>
                      ))}
                    </Swiper>
                  )}
                </>
              )
            })()}
          </div>
          <div className="md:pl-[calc(50%+10px)]">
            <div className="h2">{format(parseISO(history.onDate), "dd.MM.yyyy")} г.</div>
            <div
              className="txt mt-[10px] md:mt-[20px]"
              dangerouslySetInnerHTML={{ __html: history.body }}
            />
            <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
              <InviewTrigger>
                <RotateBox>
                  <Link to="/stories" className="btn-b no-underline">
                    все события
                  </Link>
                </RotateBox>
              </InviewTrigger>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

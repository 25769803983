import { cx } from "shared/helpers"
import { motion } from "framer-motion"
import { useRef } from "react"
import { useUnderline } from "../hooks/use-underline"

export function Underline({ children, className, reverse }) {
  const $text = useRef()
  const { borderWidth } = useUnderline($text)

  return (
    <motion.span
      variants={underlineMotion}
      transition={{ duration: 0.4 }}
      custom={{ reverse, borderWidth }}
      className={cx("fancy-underline leading-none [&>*]:leading-none", className)}
    >
      <span ref={$text}>{children}</span>
    </motion.span>
  )
}

const underlineMotion = {
  initial: c => ({
    backgroundSize: c.reverse ? `100% ${c.borderWidth}px` : `0% ${c.borderWidth}px`
  }),
  animate: c => ({
    backgroundSize: c.reverse ? `0% ${c.borderWidth}px` : `100% ${c.borderWidth}px`
  })
}

import { ReactComponent as GalkaIcon } from "../assets/galka.svg"

export function Ul({ options }) {
  return (
    <div className="grid gap-[15px]">
      {options.map(option => (
        <div key={option.id} className="flex gap-[20px] items-center">
          <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] flex items-center justify-center">
            <GalkaIcon className="block" />
          </div>
          <div className="grid pt-[0.25em]">
            <div className="whitespace-nowrap overflow-hidden text-ellipsis uppercase">
              {option.name}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

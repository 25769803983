import { Progress, Slider } from "shared/components/swiper"
import { motion, useScroll } from "framer-motion"

import { ReactComponent as Close } from "../assets/close.svg"
import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { safeJSONParse } from "shared/helpers"
import { useParams } from "react-router-dom"
import { useProject } from "../api"

export function ProjectGallery() {
  const swiperId = "gallery-swiper"

  const { scrollYProgress } = useScroll()
  const { resourceName, resourceId, imageHash } = useParams()

  const backUrl = `/${resourceName}/${resourceId}`

  const { data: { data: project, meta } = {} } = useProject({ id: resourceId })
  const content = safeJSONParse(project?.content) ?? []

  let gallery, imageIndex
  outer: for (const block of content) {
    if (block.type === "gallery") {
      for (const [index, image] of block.value.entries()) {
        if (image.hash === imageHash) {
          imageIndex = index + 1
          gallery = block
          break outer
        }
      }
    }
  }

  if ((gallery === void 0 || imageIndex === void 0) && content.length > 0) {
    throw new Error("Gallery not found")
  }

  return (
    <>
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      {gallery && (
        <>
          {/* md+ */}
          <div className="global-swiper relative hidden min-h-screen md:block">
            <Slider
              id={swiperId}
              options={{
                initialSlide: imageIndex - 1,
                fadeEffect: {
                  crossFade: true
                },
                autoplay: {
                  delay: 2500,
                  disableOnInteraction: false
                },
                spaceBetween: 16,
                slidesPerView: 1,
                effect: "fade",
                speed: 800
              }}
            >
              {gallery.value.map(image => (
                <div key={image.url} className="h-[calc(100vh-70px)] w-[100vw]">
                  <img src={image.url} className="img-cover" alt="" />
                </div>
              ))}
            </Slider>
            <div className="container-x absolute left-0 right-0 bottom-0 z-10 h-[70px] pb-[20px] dark:bg-black">
              <div className="mt-5">
                <Progress id={swiperId} total={gallery.value.length} />
              </div>
            </div>
            <button className="absolute top-[40px] left-[50%] z-10 -translate-x-[50%] text-white">
              <Link to={backUrl}>
                <Close className="h-[25px] w-[26px]" />
              </Link>
            </button>
          </div>
          {/* sm */}
          <div className="md:hidden">
            <div className="sticky top-0 h-[100px] bg-white dark:bg-black">
              <div className="px-[16px] pt-[60px]">
                <motion.div
                  className="-mb-[2px] h-[4px] origin-left bg-gray-900 dark:bg-white"
                  style={{ scaleX: scrollYProgress }}
                />
                <Progress total={gallery.value.length} />
              </div>
              <button className="absolute top-[20px] left-[50%] z-10 -translate-x-[50%] dark:text-white">
                <Link to={backUrl}>
                  <Close className="h-[25px] w-[26px]" />
                </Link>
              </button>
            </div>
            <div className="grid gap-[20px]">
              {gallery.value.map(image => (
                <img key={image.url} src={image.url} className="img-cover" alt="" />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import { useEffect, useState } from "react"

export const useUnderline = ref => {
  const [style, setStyle] = useState({ top: "100%", borderWidth: 1.5 })

  useEffect(() => {
    if (ref.current?.firstChild) {
      const target = ref.current.firstChild instanceof Element ? ref.current.firstChild : ref.current
      const compStyles = window.getComputedStyle(target)
      const fontSize = Number(compStyles.getPropertyValue("font-size").replace(/px/, ""))
      setStyle({
        top: fontSize <= 20 ? "75%" : "90%",
        borderWidth: fontSize <= 20 ? 1.5 : 2.5
      })
    }
  }, [ref])

  return style
}

import { HelmetProvider } from "react-helmet-async"
import { MetaTags } from "shared/components/seo"
import { ProgressBarWidget } from "features/misc"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "features/misc"
import { createRoutes } from "routes"
import { queryClient } from "shared/react-query"
import { useAuthStore } from "features/auth"
import { useDefaultSeo } from "shared/api"
import { useMemo } from "react"

export function App() {
  const isAuthorized = useAuthStore(state => state.isAuthorized())
  const routes = useMemo(() => createRoutes(isAuthorized), [isAuthorized])

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <RouterProvider router={routes} />
        <ProgressBarWidget />
        <MetaTagsWidget />
        <Toaster />
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function MetaTagsWidget() {
  const { data: seo } = useDefaultSeo()
  return seo ? <MetaTags title={seo.title} description={seo.description} /> : null
}

import applyCaseMiddleware from "axios-case-converter"
import axios from "axios"

const baseURL = process.env.REACT_APP_API_URL
export const http = applyCaseMiddleware(axios.create({ baseURL }), {
  // TODO:
  // игнорируются ключи, которые содержат "."
  // пропускаются трансформации ключей валидации
  preservedKeys: input => input.match(/\./g)
})

import { DEFAULT_ERROR_MESSAGE } from "./constants"

export const setAuth = getToken => config => {
  config.headers || (config.headers = {})

  const clientId = process.env.REACT_APP_CLIENT_ID
  config.headers.company = clientId

  if (typeof getToken === "function") {
    const token = getToken()
    if (token) {
      const authorization = `Bearer ${token}`
      config.headers.authorization = authorization
    } else {
      delete config.headers.authorization
    }
  }

  return config
}

export const logout = callback => error => {
  if (/*error.response.status === 400 || */ error.response.status === 401) {
    if (typeof callback === "function") {
      callback()
    }
  }
  return Promise.reject(error)
}

export const errorMessage = callback => error => {
  let errorMessage = null
  switch (error.response.status) {
    case 404:
      errorMessage = "404. Ресурс не найден"
      break
    case 412:
      const { errors } = error.response.data
      if (typeof errors === "string") {
        errorMessage = errors
      } else {
        errorMessage = null
      }
      break
    case 400:
    case 401:
    case 422: {
      const { errors } = error.response.data
      if (typeof errors === "string") {
        errorMessage = errors
      } else {
        errorMessage = JSON.stringify(errors)
      }
      break
    }
    default: {
      errorMessage = DEFAULT_ERROR_MESSAGE
    }
  }

  if (errorMessage && typeof callback === "function") {
    callback(errorMessage)
  }

  return Promise.reject(error)
}

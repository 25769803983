import { AnimatePresence, motion } from "framer-motion"
import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { memo } from "react"
import { useContacts } from "shared/api"

export const FooterMenu = memo(function BotMenu({ isOpen, onClose }) {
  const { data: contacts } = useContacts()
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="overflow-hidden"
          exit={{ height: 0 }}
          initial={{ height: 0 }}
          animate={{
            height: "auto",
            transition: { duration: 0 }
          }}
          onAnimationComplete={def => {
            if (def.height === "auto") {
              const position = document.body.scrollHeight
              window.scrollTo({
                top: position,
                behavior: "smooth"
              })
            }
          }}
        >
          <div className="bg-white dark:bg-black dark:text-white">
            <div className="container-x container-y">
              <div className="grid-cols-10 md:grid">
                <div className="col-span-4">
                  {contacts && (
                    <div className="md:hidden">
                      {contacts.phone && <Phone phone={contacts.phone} />}
                      <Address email={contacts.email} address={contacts.address} />
                    </div>
                  )}
                  <nav className="mt-[20px] md:mt-[0px]">
                    <span>
                      <HoverTrigger>
                        <Underline>
                          <Link className="h3" to="/about" onClick={onClose}>
                            о бюро
                          </Link>
                        </Underline>
                      </HoverTrigger>
                    </span>
                    <div className="mt-[20px]">
                      <span>
                        <HoverTrigger>
                          <Underline>
                            <Link className="h3" to="/stories" onClick={onClose}>
                              история
                            </Link>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div className="mt-[20px]">
                      <span>
                        <HoverTrigger>
                          <Underline>
                            <Link className="h3" to="/achievements" onClick={onClose}>
                              достижения
                            </Link>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div className="mt-[20px]">
                      <span>
                        <HoverTrigger>
                          <Underline>
                            <Link className="h3" to="/team" onClick={onClose}>
                              команда
                            </Link>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div className="mt-[20px]">
                      <span>
                        <HoverTrigger>
                          <Underline>
                            <Link className="h3" to="/news" onClick={onClose}>
                              новости
                            </Link>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div className="mt-[20px]">
                      <span>
                        <HoverTrigger>
                          <Underline>
                            <Link className="h3" to="/contacts" onClick={onClose}>
                              контакты
                            </Link>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                  </nav>
                </div>
                <div className="col-span-3 flex items-center">
                  <div className="grid gap-[20px] mt-[20px] md:mt-[0]">
                    <div>
                      <span className="h3 text-[#C4C4C4]">
                        <HoverTrigger>
                          <Underline>
                            <a
                              href={`${process.env.PUBLIC_URL}/docs/soglasie.pdf`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Согласие на обработку персональных данных
                            </a>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div>
                      <span className="h3 text-[#C4C4C4]">
                        <HoverTrigger>
                          <Underline>                          
                            <a
                              href={`${process.env.PUBLIC_URL}/docs/policy.pdf`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Политика обработки персональных данных
                            </a>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div>
                      <span className="h3 text-[#C4C4C4]">
                        <HoverTrigger>
                          <Underline>
                            <a
                              href={`${process.env.PUBLIC_URL}/docs/soglashenie.pdf`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Соглашение об использовании сайта
                            </a>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                    <div>
                      <span className="h3 text-[#C4C4C4]">
                        <HoverTrigger>
                          <Underline>
                            <a
                              href={`${process.env.PUBLIC_URL}/docs/svedenia.pdf`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Сведения о реализуемых требованиях
                            </a>
                          </Underline>
                        </HoverTrigger>
                      </span>
                    </div>
                  </div>
                </div>
                {contacts && (
                  <div className="col-span-3 hidden flex-col items-end md:flex">
                    <div className="flex-1 text-right">{contacts.phone && <Phone phone={contacts.phone} />}</div>
                    <div className="pb-[40px] text-right">
                      <Address email={contacts.email} address={contacts.address} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
})

function Phone({ phone }) {
  return (
    <HoverTrigger>
      <Underline reverse>
        <a className="h3 md:btn md:no-underline" href={`tel:${phone}`}>
          {phone}
        </a>
      </Underline>
    </HoverTrigger>
  )
}

function Address({ email, address }) {
  return (
    <>
      {email && (
        <div className="mt-[10px]">
          <HoverTrigger>
            <Underline>
              <a className="h3 md:text-[20px]" href={`mailto:${email}`}>
                {email}
              </a>
            </Underline>
          </HoverTrigger>
        </div>
      )}
      {address && (
        <div className="mt-[10px]">
          <div className="h3 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: address }} />
        </div>
      )}
    </>
  )
}

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useSettings }

async function getSettings() {
  const resp = await http.get("/settings")
  return resp.data
}

function useSettings(config) {
  return useQuery({
    ...config,
    queryKey: ["settings"],
    queryFn: getSettings
  })
}

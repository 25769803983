import { Checkbox, ControlLabel, Fieldset, Form } from "shared/components/form"
import { HoverTrigger, Underline } from "shared/components/anime"
import { InviewTrigger, RotateBox } from "shared/components/anime"
import { PhoneField, Placeholder } from "shared/components/form"
import { TextField, TextareaField } from "shared/components/form"
import { useProfile, useUpdateProfile } from "../api"

import { Controller } from "react-hook-form"
import { Link } from "react-router-dom"
import { Navigation } from "../componets/navigation"
import { makeValidation } from "shared/helpers"
import { useNavigate } from "react-router-dom"
import { useSpheres } from "shared/api"

export function EditProfile() {
  const navigate = useNavigate()

  const { data: spheres } = useSpheres()
  const { data: profile } = useProfile({
    config: {
      select: data => {
        const spheres = data.info.spheres
        data.info.spheres = spheres.map(String)
        return data
      }
    }
  })

  const { mutate: updateProfile, isLoading } = useUpdateProfile()
  const submitForm = (payload, { setError }) => {
    updateProfile(payload, {
      onSuccess: () => navigate("/cabinet"),
      onError: error => makeValidation(error, setError)
    })
  }

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">профиль</h1>
      </div>
      <div className="md:hidden text-center mt-[30px]">
        <Navigation />
      </div>
      <div className="mt-[20px] md:mt-[80px] 2xl:mt-[100px]">
        {profile && (
          <Form onSubmit={submitForm} options={{ defaultValues: profile }}>
            {({ register, formState, control, setValue, watch }) => (
              <>
                <div className="grid-cols-10 gap-[40px] md:grid">
                  <div className="col-span-5 hidden md:block">
                    <Navigation />
                  </div>
                  <div className="col-span-4">
                    {profile && (
                      <>
                        <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                          *Для изменения ФИО/Названия компании напишите письмо на
                          HELLO@AU-PROJECT.RU
                        </div>
                        <div className="grid gap-[20px] mt-[10px]">
                          <Placeholder>{profile.name}</Placeholder>
                        </div>
                        <div className="mt-[20px]">
                          <Controller
                            name="phone"
                            control={control}
                            render={() => (
                              <PhoneField
                                placeholder="телефон"
                                defaultValue={profile.phone}
                                errorMessage={formState.errors.phone?.message}
                                onValueChange={values => setValue("phone", values.value)}
                              />
                            )}
                          />
                        </div>
                        <div className="mt-[20px]">
                          <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                            *Email будет использоваться для авторизации в личном кабинете партнера.
                          </div>
                          <div className="mt-[10px]">
                            <Placeholder>{profile.email}</Placeholder>
                          </div>
                        </div>
                        <div className="mt-[20px]">
                          <div className="text-[12px] md:text-[14px] 2xl:text-[15px]">
                            *Отметьте галочкой направления, в которых специализируютесь.
                          </div>
                        </div>
                        {spheres && (
                          <div className="mt-[10px]">
                            <Fieldset
                              label="Направления"
                              active={watch("info.spheres").length > 0}
                              errorMessage={formState.errors.info?.spheres?.message}
                            >
                              <div className="mt-[10px] md:mt-[20px]">
                                {spheres.map(sp => (
                                  <div key={sp.id} className="mt-[10px]">
                                    <ControlLabel label={sp.name}>
                                      <Checkbox {...register("info.spheres")} value={sp.id} />
                                    </ControlLabel>
                                  </div>
                                ))}
                              </div>
                            </Fieldset>
                          </div>
                        )}
                        <div className="mt-[20px]">
                          <TextareaField
                            rows="4"
                            label="Расскажите о себе"
                            placeholder="Описание"
                            defaultValue={profile.info.description}
                            errorMessage={formState.errors.info?.description?.message}
                            {...register("info.description")}
                          />
                        </div>
                        <div className="mt-[20px]">
                          <TextField
                            placeholder="Cсылка на портфолио"
                            defaultValue={profile.info.portfolioLink}
                            errorMessage={formState.errors.info?.portfolioLink?.message}
                            {...register("info.portfolioLink")}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid-cols-10 gap-[40px] md:grid mt-[40px] md:mt-[60px] md:text-right 2xl:mt-[100px]">
                  <div className="col-start-6 col-end-11">
                    <div className="flex items-center justify-between">
                      <HoverTrigger>
                        <Underline reverse>
                          <Link to="/cabinet/change-password" className="btn no-underline">
                            Изменить пароль
                          </Link>
                        </Underline>
                      </HoverTrigger>
                      <InviewTrigger>
                        <RotateBox>
                          <button type="submit" disabled={isLoading} className="btn-b no-underline">
                            Сохранить
                          </button>
                        </RotateBox>
                      </InviewTrigger>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </div>
    </div>
  )
}

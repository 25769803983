import { BannersSwiper } from "./banners-swiper"
import { memo } from "react"
import { useBanners } from "../api"
import { useNavigate } from "react-router-dom"

export const BannersWidget = memo(function BannersContainer() {
  const navigate = useNavigate()
  const { data: banners = [] } = useBanners()

  function onBannerClick(banner) {
    if (banner.link) {
      if (banner.linkTarget === "_blank") {
        window.open(banner.link)
      } else {
        navigate(banner.link)
      }
    }
  }

  return <BannersSwiper banners={banners} onBannerClick={onBannerClick} />
})

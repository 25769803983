import { http } from "shared/http"
import { useQuery } from "react-query"

export { useOffices }

function getOffices() {
  return http("offices/main")
}

function useOffices({ config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["offices-main"],
    queryFn: getOffices
  })
}

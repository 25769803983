import { http } from "shared/http"
import { useQuery } from "react-query"

export { useNews, useNewsItem }

function getNewsById(id) {
  return http(`/news/${id}`)
}

function getNews(query = {}) {
  return http("/news", {
    params: {
      limit: query.limit
    }
  })
}

function useNews({ query, config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["news-list"],
    queryFn: () => getNews(query)
  })
}

function useNewsItem({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["news-item", id],
    queryFn: () => getNewsById(id),
    useErrorBoundary: true
  })
}

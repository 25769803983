import { cx } from "shared/helpers"

export function Placeholder({ children, label }) {
  return (
    <div className="overflow-hidden">
      <div
        className={cx(
          "border-2 border-gray-900/30 dark:border-white/30 px-[20px] md:px-[30px] 2xl:px-[40px] cursor-not-allowed flex justify-center flex-col",
          {
            "h-full py-[20px] md:py-[30px] 2xl:py-[40px]": label,
            "h-[60px] md:h-[90px] 2xl:h-[110px]": !label
          }
        )}
      >
        {label ? (
          <>
            <div className="h2">{label}</div>
            <div className="h3 mt-[10px] md:mt-[20px] overflow-hidden text-ellipsis normal-case">
              {children}
            </div>
          </>
        ) : (
          <div className="h2 overflow-hidden text-ellipsis normal-case">{children}</div>
        )}
      </div>
    </div>
  )
}

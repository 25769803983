import { useSettings } from "./settings"

export { useDefaultSeo }

function useDefaultSeo(config) {
  return useSettings({
    select: data => ({
      title: data.defaultMeta.title,
      description: data.defaultMeta.description
    }),
    ...config
  })
}

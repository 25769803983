import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"

export function About() {
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">о бюро</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/about">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="mt-[20px]">
        <div className="aspect-[288/162] md:aspect-[1283/465]">
          <img loading="lazy" src={require("../assets/6.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[20px] md:mt-[60px] md:pl-[calc(50%+20px)] 2xl:mt-[100px]">
        <div className="txt">
          Архитектурно-проектное бюро «Аркитект бай Унистрой» c 2017 года успешно реализует проекты для жилой и
          коммерческой недвижимости в сфере девелопмента. Предметом деятельности АПБ является: разработка концепции
          будущего объекта, генеральный план, эскизное проектирование, выпуск проекта по благоустройству, цветовые
          решения фасадов, разработка стадийного проекта.
          <br />
          <br />
          Одним из ключевых показателей работы АПБ A|U является общая площадь качественно запроектированных и введённых
          в эксплуатацию объектов недвижимости с коммерческим блоком которые были высоко оценены ведущими российскими
          архитекторами.
        </div>
      </div>
      <div className="mt-[20px] md:hidden">
        <div className="aspect-[288/288]">
          <img loading="lazy" src={require("../assets/7.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[20px] md:hidden">
        <div className="txt">
          Главная ценность АПБ A|U - качество социальной и городской среды, повышение уровня комфорта населения,
          экология, развитие интереса к физической культуре подрастающего поколения, внедрение инновационных технических
          и архитектурных решений.
        </div>
      </div>
      <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px]">
        <div className="grid-cols-10 gap-[40px] md:grid">
          <div className="col-span-2 hidden items-center md:flex">
            <div className="aspect-[240/300]">
              <img loading="lazy" src={require("../assets/7.jpg")} className="img-cover" alt="" />
            </div>
          </div>
          <div className="col-span-5 col-start-6">
            <div className="aspect-[288/162]">
              <img loading="lazy" src={require("../assets/8.jpg")} className="img-cover" alt="" />
            </div>
          </div>
        </div>
        <div className="txt mt-[60px] hidden md:block md:pl-[calc(50%+20px)] 2xl:mt-[100px]">
          Главная ценность АПБ A|U - качество социальной и городской среды, повышение уровня комфорта населения,
          экология, развитие интереса к физической культуре подрастающего поколения, внедрение инновационных технических
          и архитектурных решений.
        </div>
      </div>
      <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link className="btn-b no-underline" to="/about">
              подробнее о бюро
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </>
  )
}

import { useMutation, useQuery } from "react-query"

import { http } from "shared/http"
import { queryClient } from "shared/react-query"
import { useAuthStore } from "features/auth"

export { useProfile, useUpdateProfile }

async function getSelf() {
  const resp = await http.get("/auth/me")
  return resp.data
}

function updateProfile(profile) {
  return http.patch("/auth/me", profile)
}

function useProfile({ config } = {}) {
  const token = useAuthStore(state => state.token)
  const isAuthorized = useAuthStore(state => state.isAuthorized())

  return useQuery({
    ...config,
    queryKey: ["auth-me", token],
    queryFn: getSelf,
    enabled: isAuthorized
  })
}

function useUpdateProfile({ config } = {}) {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["auth-me"])
    },
    ...config,
    mutationFn: updateProfile
  })
}

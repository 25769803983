import { format, parseISO } from "date-fns"

import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

export function ExtraNewsCard({ datetime, name, picture, lead, id }) {
  const navigate = useNavigate()
  const href = `/news/${id}`

  return (
    <div className="md:grid md:grid-cols-10 md:gap-[20px]" onClick={() => navigate(href)}>
      <div className="md:col-span-5">
        <div className="aspect-[288/162] md:aspect-[633/356] 2xl:aspect-[910/512]">
          <img loading="lazy" src={picture} className="img-cover" alt="" />
        </div>
      </div>
      <div className="md:col-span-5">
        <div className="h3 mt-[20px] md:mt-[0px]">{format(parseISO(datetime), "dd.MM.yyyy")} г.</div>
        <div className="h1 mt-[15px] md:mt-[20px]">{name}</div>
        <div className="txt mt-[20px] md:mt-[40px]">{lead}</div>
        <div className="mt-[20px] 2xl:mt-[40px]">
          <Link className="btn" to={href} onClick={ev => ev.stopPropagation()}>
            читать
          </Link>
        </div>
      </div>
    </div>
  )
}

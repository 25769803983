import { ReactComponent as CheckOn } from "../assets/galka.svg"
import { Fieldset } from "./fieldset"
import { cx } from "shared/helpers"
import { forwardRef } from "react"
import { useContext } from "react"

export const Checkbox = forwardRef((props, ref) => {
  const isFieldsetActive = useContext(Fieldset.Context)
  return (
    <div className="relative h-[30px] w-[30px] min-h-[30px] min-w-[30px] outline-none">
      <input type="checkbox" className="peer opacity-0" ref={ref} {...props} />
      <div className="bg:white absolute top-0 right-0 bottom-0 left-0 hidden items-center justify-center peer-checked:flex peer-checked:bg-gray-900 dark:peer-checked:bg-white">
        <CheckOn className="h-[130x] w-[19px]" />
      </div>
      <div
        className={cx(
          "absolute top-0 right-0 bottom-0 left-0 border-2 border-gray-900 dark:border-white peer-checked:border-transparent opacity-30 peer-checked:opacity-100 group-hover:opacity-100",
          { "border-white dark:border-gray-900": isFieldsetActive }
        )}
      />
    </div>
  )
})

import { HoverTrigger, Underline } from "shared/components/anime"

import { Contacts } from "../components/contacts"
import { EmployeeCard } from "../components/employee-card"
import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { useContacts } from "shared/api"
import { useEmployees } from "../api"

export function Employees() {
  const { data: contacts } = useContacts()
  const { data: { data: employees, meta } = {}, isLoading } = useEmployees()

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      <div className="md:pl-[calc(50%+10px)]">
        <h1 className="h1 text-center md:text-left">команда профессионалов</h1>
      </div>
      {contacts?.email && (
        <div className="mt-[20px] md:hidden">
          <Contacts email={contacts.email} />
        </div>
      )}
      <div className="mt-[20px] md:mt-[60px]">
        <div className="grid-cols-10 gap-[20px] md:grid">
          <div className="col-span-3 hidden items-center md:flex">
            {!isLoading && <div>{contacts?.email && <Contacts email={contacts.email} />}</div>}
          </div>
          <div className="col-span-7">
            {employees?.length > 0 && (
              <div className="grid grid-cols-2 gap-x-[8px] gap-y-[20px] md:grid-cols-7 md:gap-x-[20px] md:gap-y-[40px] 2xl:gap-y-[80px]">
                {employees.map(employee => (
                  <Link key={employee.id} to={`/team/${employee.id}`}>
                    <EmployeeCard name={employee.name} surname={employee.surname} image={employee.pictureUrl} />
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[40px] md:mt-[100px] md:pl-[calc(50%+10px)] 2xl:mt-[160px]">
        <div className="h2">
          Мы уверенно воплощаем в жизнь масштабные{" "}
          <HoverTrigger className="inline">
            <Underline reverse>
              <Link to="/projects" className="no-underline">
                ПРОЕКТЫ
              </Link>
            </Underline>
          </HoverTrigger>{" "}
          жилой и коммерческой недвижимости.
          <div className="mt-[20px] md:mt-[40px]">
            Наша дружная команда всегда открыта к{" "}
            <HoverTrigger className="inline">
              <Underline reverse>
                <Link to="/cooperation" className="no-underline">
                  СОТРУДНИЧЕСТВУ
                </Link>
              </Underline>
            </HoverTrigger>{" "}
            с талантливыми профессионалами.
          </div>
        </div>
      </div>
    </div>
  )
}

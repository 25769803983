import { HiLightningBolt } from "react-icons/hi"

export function Toast({ message }) {
  return (
    <div className="flex flex-row items-center w-96 bg-gray-900 px-4 py-6 text-white transform-gpu translate-y-0 hover:translate-y-1 relative transition-all duration-500 ease-in-out dark:bg-white dark:text-gray-900">
      <div className="relative -top-[0.15em]">
        <HiLightningBolt />
      </div>
      <div className="h3 pl-6">{message}</div>
    </div>
  )
}

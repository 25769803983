import { Achievement, Achievements } from "features/achievements"
import { BlankLayout, Layout } from "features/misc"
import { Employee, Employees } from "features/team"
import { ForgotPassword, ResetPassword, SignIn, SignUp } from "features/auth"
import { News, NewsItem } from "features/news"
import { Project, ProjectGallery, Projects } from "features/projects"
import { Stories, Story } from "features/stories"

import { About } from "features/about"
import { Contacts } from "features/contacts"
import { Cooperation } from "features/cooperation"
import { Landing } from "features/landing"
import { NotFound } from "shared/components/not-found"

export const publicRoutes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Landing />
      },
      {
        path: "/contacts",
        element: <Contacts />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/team",
        element: <Employees />
      },
      {
        path: "/team/:id",
        element: <Employee />
      },
      {
        path: "/news",
        element: <News />
      },
      {
        path: "/news/:id",
        element: <NewsItem />
      },
      {
        path: "/achievements",
        element: <Achievements />
      },
      {
        path: "/achievements/:id",
        element: <Achievement />
      },
      {
        path: "/stories",
        element: <Stories />
      },
      {
        path: "/stories/:id",
        element: <Story />
      },
      {
        path: "/cooperation",
        element: <Cooperation />
      },
      {
        path: "/auth/sign-up",
        element: <SignUp />
      },
      {
        path: "/auth/sign-in",
        element: <SignIn />
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "/projects",
        element: <Projects />
      },
      {
        path: "/projects/:id",
        element: <Project />
      },
      {
        path: "/password/reset",
        element: <ResetPassword />
      }
    ]
  },
  {
    path: "/gallery/:resourceName/:resourceId/:imageHash",
    element: <BlankLayout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <ProjectGallery />
      }
    ]
  }
]

import { HoverTrigger, Underline } from "shared/components/anime"

import { motion } from "framer-motion"
import { useState } from "react"

export function EmployeeCard({ name, surname, image }) {
  const [ready, setReady] = useState(!Boolean(image))
  const photo = image ? image : require("../assets/no-person-photo.png")

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: +ready, y: ready ? 0 : -10 }}
      transition={{ duration: 0.3 }}
    >
      <HoverTrigger>
        <div className="aspect-[100/100]">
          <img
            loading="lazy"
            src={photo}
            className="block h-full w-full object-cover"
            onLoad={() => setReady(true)}
            alt=""
          />
        </div>
        <div className="mt-[10px] text-xs uppercase md:text-sm 2xl:text-base">
          <Underline>
            {name}
            <br />
            {surname}
          </Underline>
        </div>
      </HoverTrigger>
    </motion.div>
  )
}

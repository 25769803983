import { useEffect } from "react"
import { useScrollbarWidth } from "shared/hooks"

export function useApplyModalStyle(isOpen) {
  const scrollbarWidth = useScrollbarWidth()

  useEffect(() => {
    const overflow = isOpen ? "hidden" : "auto"
    const padding = isOpen ? scrollbarWidth : 0
    document.body.style.overflow = overflow
    document.body.style.paddingRight = padding + "px"
  }, [isOpen, scrollbarWidth])

  return {
    open: isOpen,
    scrollbarOffset: scrollbarWidth
  }
}

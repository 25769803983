import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useAuthStore } from "features/auth"

export function RequireAuth() {
  const location = useLocation()
  const isAuthorized = useAuthStore(state => state.isAuthorized())

  if (isAuthorized) {
    return <Outlet />
  }

  return <Navigate to="/auth/sign-in" state={{ from: location }} />
}

import { HoverTrigger, ScaleBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { Swiper } from "shared/components/swiper"
import { useNavigate } from "react-router-dom"

export function ProjectsSwiper({ projects }) {
  const navigate = useNavigate()
  return (
    <Swiper
      id="news-swiper"
      options={{
        slidesPerView: "auto",
        breakpoints: { 768: { spaceBetween: 20 } },
        spaceBetween: 8,
        cssMode: true
      }}
    >
      {projects.map(project => (
        <div
          key={project.id}
          className="w-[140px] cursor-pointer md:w-[50vw]"
          onClick={() => navigate(`/projects/${project.id}`)}
        >
          <div className="h2 md:h3 mb-[10px] overflow-hidden text-ellipsis whitespace-nowrap">{project.name}</div>
          <HoverTrigger>
            <ScaleBox className="h-[160px] md:h-[465px] 2xl:h-[670px]">
              <img src={project.pictureUrl} loading="lazy" className="img-cover w-auto" alt="" />
            </ScaleBox>
            <div className="mt-[10px] text-right uppercase">
              <Underline reverse>
                <Link
                  to={`/projects/${project.id}`}
                  className="md:btn text-xs md:no-underline"
                  onClick={ev => ev.preventDefault()}
                >
                  подробнее <span className="hidden md:inline">о проекте</span>
                </Link>
              </Underline>
            </div>
          </HoverTrigger>
        </div>
      ))}
    </Swiper>
  )
}

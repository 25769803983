import { AnimatePresence, motion } from "framer-motion"
import { HoverTrigger, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { memo } from "react"
import { useApplyModalStyle } from "../hooks/use-apply-modal-style"
import { useAuthStore } from "features/auth"
import { useContacts } from "shared/api"
import { useLogout } from "features/auth"
import { useProfile } from "features/profile"

export const HeaderMenu = memo(function HeaderMenu({ isOpen, onClose, onAnimationState }) {
  const { data: contacts } = useContacts()

  useApplyModalStyle(isOpen)

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed top-0 left-0 z-20 h-full w-full overflow-auto overflow-x-hidden pt-[56px] dark:text-white md:pt-[110px] 2xl:pt-[125px]">
          <motion.div
            className="absolute right-0 bottom-0 left-0 top-0 origin-left bg-white dark:bg-black"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1, transition: { duration: 1.5, type: "spring" } }}
            exit={{ scaleX: 0, transition: { duration: 1.5, delay: 1.5 / 2, type: "spring" } }}
            onAnimationComplete={def => def.scaleX === 1 && onAnimationState("open-complete")}
          />
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0, transition: { duration: 1.5, delay: 1.5 / 3, type: "spring" } }}
            exit={{ x: "-100%", transition: { duration: 1.5, type: "spring" } }}
            onAnimationComplete={def => def.x === "-100%" && onAnimationState("close-complete")}
            onAnimationStart={def =>
              def.x === 0 ? onAnimationState("before-open") : onAnimationState("before-close")
            }
          >
            <div className="container-x container-y">
              <div className="md:hidden">
                <Profile onClose={onClose} />
                <div className="mt-[20px]">
                  <PrimaryNav onClose={onClose} />
                </div>
              </div>
              {contacts && (
                <div className="hidden md:block">
                  <Contacts phone={contacts.phone} email={contacts.email} />
                </div>
              )}
              <div className="mt-[40px] grid-cols-10 gap-[20px] md:mt-[20px] md:grid">
                <div className="col-span-3 col-start-2 flex">
                  <nav className="flex flex-col gap-[15px]">
                    {NAV_LINKS.map(([name, href]) => (
                      <HoverTrigger key={href}>
                        <Underline>
                          <Link to={href} onClick={onClose} className="h1 md:h2">
                            {name}
                          </Link>
                        </Underline>
                      </HoverTrigger>
                    ))}
                  </nav>
                </div>
                <div className="col-span-6 hidden md:block">
                  <PrimaryNav onClose={onClose} />
                  <div className="mt-[10px] 2xl:mt-[20px]">
                    <Profile onClose={onClose} />
                  </div>
                </div>
              </div>
              {contacts && (
                <div className="mt-[20px] md:hidden">
                  <Contacts phone={contacts.phone} email={contacts.email} />
                </div>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
})

function Profile({ onClose }) {
  const logout = useLogout()
  const { data: profile } = useProfile()
  const isAuthorized = useAuthStore(state => state.isAuthorized())

  return (
    <div className="text-right">
      {isAuthorized ? (
        <>
          <div className="flex gap-[15px] justify-end">
            <HoverTrigger>
              <Underline reverse>
                <Link to="/cabinet/tenders" onClick={onClose}>
                  <button className="btn no-underline">заказы</button>
                </Link>
              </Underline>
            </HoverTrigger>
            <HoverTrigger>
              <Underline reverse>
                <button className="btn no-underline" onClick={logout}>
                  выход
                </button>
              </Underline>
            </HoverTrigger>
          </div>
          {profile && (
            <div className="mt-[10px]">
              <span className="text-[#C4C4C4]">
                <HoverTrigger>
                  <Underline reverse>
                    <Link to="/cabinet" className="btn no-underline" onClick={onClose}>
                      {profile.name}
                    </Link>
                  </Underline>
                </HoverTrigger>
              </span>
            </div>
          )}
        </>
      ) : (
        <HoverTrigger>
          <Underline reverse>
            <Link to="/auth/sign-in" className="btn no-underline" onClick={onClose}>
              Вход для партнеров
            </Link>
          </Underline>
        </HoverTrigger>
      )}
    </div>
  )
}

function Contacts({ phone, email }) {
  return (
    <div className="text-right">
      {phone && (
        <a className="h3" href={`tel:${phone}`}>
          {phone}
        </a>
      )}
      {email && (
        <div className="mt-[10px]">
          <a className="h3" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      )}
    </div>
  )
}

function PrimaryNav({ onClose }) {
  return (
    <div className="grid grid-cols-2 gap-[8px] md:gap-[20px]">
      <HoverTrigger>
        <Link to="/projects" onClick={onClose}>
          <div className="relative aspect-[140/92]">
            <img
              fetchpriority="high"
              src={require("../assets/projects.jpg")}
              className="img-cover"
              alt=""
            />
            <div className="h3 md:h2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
              <Underline>проекты</Underline>
            </div>
          </div>
        </Link>
      </HoverTrigger>
      <HoverTrigger>
        <Link to="/cooperation" onClick={onClose}>
          <div className="relative aspect-[140/92]">
            <img
              fetchpriority="high"
              src={require("../assets/cooperation.jpg")}
              className="img-cover"
              alt=""
            />
            <div className="h3 md:h2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white">
              <Underline>сотрудничество</Underline>
            </div>
          </div>
        </Link>
      </HoverTrigger>
    </div>
  )
}

const NAV_LINKS = [
  ["о бюро", "/about"],
  ["история", "/stories"],
  ["достижения", "/achievements"],
  ["команда", "/team"],
  ["новости", "/news"],
  ["контакты", "/contacts"]
]

import { About } from "../components/about"
import { Achievements } from "../components/achievements"
import { Contacts } from "../components/contacts"
import { Hero } from "../components/hero"
import { News } from "../components/news"
import { Projects } from "../components/projects"
import { Team } from "../components/team"

export function Landing() {
  return (
    <div className="container-x container-y">
      <Hero />
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <About />
      </div>
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <Projects />
      </div>
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <Team />
      </div>
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <Achievements />
      </div>
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <News />
      </div>
      <div className="mt-[80px] md:mt-[200px] 2xl:mt-[350px]">
        <Contacts />
      </div>
    </div>
  )
}

import { CookieInformer } from "./cookie-informer"
import { Cursor } from "shared/components/cursor"
import { Outlet } from "react-router-dom"
import { ScrollRestoration } from "react-router-dom"
import { useApplyThemeStyle } from "../hooks/use-apply-theme-style"

export function BlankLayout() {
  useApplyThemeStyle()
  return (
    <>
      <ScrollRestoration />
      <CookieInformer />
      <Cursor />
      <Outlet />
    </>
  )
}

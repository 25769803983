import { motion } from "framer-motion"

export function InviewTrigger({ children, className }) {
  return (
    <motion.span
      initial="initial"
      whileInView="animate"
      whileHover="hover"
      viewport={{ once: false, margin: "5% 0px 5% 0px" }}
      className={className}
    >
      {children}
    </motion.span>
  )
}

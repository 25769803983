import {
  HoverTrigger,
  InviewTrigger,
  RotateBox,
  Underline
} from "shared/components/anime"

import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { cx } from "shared/helpers"
import { declOfNum } from "shared/helpers"
import { useStories } from "../api"
import { useStoriesStore } from "../store"

export function Stories() {
  const { data: { data: history, meta } = {} } = useStories()
  // изначально нужно показать только 3 года + нарушена сортировка
  const showAll = useStoriesStore(state => state.showAll)
  const allIsShown = useStoriesStore(state => state.allIsShown)

  const eventsByYears = history ?? []
  // сортируем года по убыванию
  const allYears = Object.keys(eventsByYears).sort((a, b) => b - a)
  const years = allYears.slice(0, allIsShown ? allYears.length : INITIAL_COUNT)
  const restYearsCount = allYears.length - INITIAL_COUNT
  const btnIsShown = allYears.length > INITIAL_COUNT && !allIsShown
  const btnSuffix = declOfNum(restYearsCount, ["cобытие", "события", "событий"])

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      <div className="text-center uppercase md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">история</h1>
      </div>
      <div className="mt-[40px] md:mt-[60px] 2xl:mt-[100px]">
        {years.map((year, index) => (
          <div
            key={year}
            className={cx({ "mt-[60px] md:mt-[80px] 2xl:mt-[200px]": index > 0 })}
          >
            <div className="h1">{year}</div>
            {eventsByYears[year].map(ev => (
              <div key={ev.id} className="mt-[20px] md:pl-[calc(50%+10px)]">
                <div className="h2">
                  <HoverTrigger>
                    <Underline>
                      <Link to={`/stories/${ev.id}`}>{ev.name}</Link>
                    </Underline>
                  </HoverTrigger>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {btnIsShown && (
        <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
          <InviewTrigger>
            <RotateBox>
              <button className="btn-b no-underline" onClick={showAll}>
                +{restYearsCount} {btnSuffix}
              </button>
            </RotateBox>
          </InviewTrigger>
        </div>
      )}
    </div>
  )
}

const INITIAL_COUNT = 3

import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { NewsWidget } from "features/news"

export function News() {
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">новости</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/news">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="mt-[20px]">
        <NewsWidget />
      </div>
      <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link className="btn-b no-underline" to="/news">
              все новости
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </>
  )
}

import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { AchievementsWidget } from "features/achievements"
import { Link } from "react-router-dom"

export function Achievements() {
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">достижения</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/achievements">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="mt-[20px]">
        <div className="aspect-[288/162] md:aspect-[1286/435]">
          <img loading="lazy" src={require("../assets/18.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[40px] grid-cols-10 gap-[40px] md:mt-[60px] md:grid 2xl:mt-[100px]">
        <div className="col-span-4 col-start-2 hidden items-center md:flex">
          <div className="hidden dark:block">
            <img loading="lazy" src={require("../assets/19.svg").default} className="block w-[110px]" alt="" />
          </div>
          <div className="dark:hidden">
            <img loading="lazy" src={require("../assets/20.svg").default} className="block w-[110px]" alt="" />
          </div>
        </div>
        <div className="col-span-5 col-start-6">
          <AchievementsWidget />
        </div>
      </div>
      <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link className="btn-b no-underline" to="/achievements">
              все достижения
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </>
  )
}

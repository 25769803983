import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"
import { MetaTags } from "shared/components/seo"
import { useEmployee } from "../api"
import { useParams } from "react-router-dom"

export function Employee() {
  const id = useParams().id
  const { data: { data: employee, meta } = {} } = useEmployee({ id })

  return (
    <div className="container-x container-y">
      {meta && <MetaTags title={meta.title} description={meta.description} />}
      {employee && (
        <>
          <div className="text-center uppercase md:pl-[calc(50%+10px)] md:text-left">
            <div className="h1">
              <div>{employee.name}</div>
              <div>{employee.surname}</div>
            </div>
            <div className="h3 mt-[20px]">{employee.position}</div>
          </div>
          <div className="grid-cols-10 gap-[20px] md:mt-[80px] md:grid">
            <div className="col-span-4 mt-[20px] md:mt-[0px]">
              <div className="aspect-[100/100]">
                <img
                  loading="lazy"
                  src={employee.pictureUrl || DEFAULT_PHOTO}
                  className="block h-full w-full object-cover"
                  alt=""
                />
              </div>
            </div>
            <div />
            <div className="col-span-5 mt-[40px] flex-col md:mt-[0px] md:flex">
              {employee.about === null && (
                <div className="mb-[40px] flex justify-center md:mb-0 md:justify-start md:pt-[100px] md:pl-[10vw]">
                  <div className="hidden dark:block">
                    <img
                      src={require("../assets/no-data-light.svg").default}
                      className="block w-[140px] md:w-[240px] 2xl:w-[350px]"
                      alt=""
                    />
                  </div>
                  <div className="dark:hidden">
                    <img
                      src={require("../assets/no-data-light.svg").default}
                      className="block w-[140px] md:w-[240px] 2xl:w-[350px]"
                      alt=""
                    />
                  </div>
                </div>
              )}
              <div className="txt flex-1">{employee.about}</div>
              <div>
                <div className="mt-[20px] text-right">
                  <InviewTrigger>
                    <RotateBox>
                      <Link to="/team" className="btn-b uppercase no-underline">
                        подробнее о команде
                      </Link>
                    </RotateBox>
                  </InviewTrigger>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const DEFAULT_PHOTO = require("../assets/no-person-photo.png")

import { HoverTrigger, InviewTrigger, RotateBox, Underline } from "shared/components/anime"

import { Link } from "react-router-dom"
import { ProjectsWidget } from "features/projects"

export function Projects() {
  return (
    <>
      <div className="flex items-end">
        <div className="h1 flex-1">проекты</div>
        <div>
          <HoverTrigger>
            <Underline reverse>
              <Link className="btn no-underline" to="/projects">
                подробнее
              </Link>
            </Underline>
          </HoverTrigger>
        </div>
      </div>
      <div className="mt-[20px]">
        <div className="aspect-[288/162] md:aspect-[1286/435]">
          <img loading="lazy" src={require("../assets/13.jpg")} className="img-cover" alt="" />
        </div>
      </div>
      <div className="mt-[12px] md:mt-[100px] 2xl:mt-[160px]">
        <ProjectsWidget />
      </div>
      <div className="mt-[20px] text-right md:mt-[60px] 2xl:mt-[100px]">
        <InviewTrigger>
          <RotateBox>
            <Link className="btn-b no-underline" to="/projects">
              все проекты
            </Link>
          </RotateBox>
        </InviewTrigger>
      </div>
    </>
  )
}

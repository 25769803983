import { HoverTrigger, ScaleBox } from "shared/components/anime"

import { Swiper } from "shared/components/swiper"
import { cx } from "shared/helpers"
import { useState } from "react"

export function BannersSwiper({ banners, onBannerClick }) {
  const [headBanner] = banners
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <>
      <div className="text-center md:pl-[calc(50%+20px)] md:text-left">
        <h1 className="h3 md:h2 min-h-[50px] md:min-h-[70px] 2xl:min-h-[90px]" key={slideIndex}>
          {banners[slideIndex]?.name}
        </h1>
      </div>
      <div className="md:mt-[20px]">
        {banners.length === 0 && (
          <div className="aspect-[288/162] bg-gray-200 bg-cover dark:bg-black md:aspect-[1286/465] 2xl:md:aspect-[1840/670]" />
        )}
        {/* только один баннер */}
        {banners.length === 1 && (
          <div
            onClick={() => onBannerClick(headBanner)}
            className={cx(
              "relative aspect-[288/160] md:aspect-[1286/465] 2xl:md:aspect-[1840/670]",
              { "cursor-pointer": Boolean(headBanner.link) }
            )}
          >
            <HoverTrigger className="h-full">
              <ScaleBox>
                <img src={headBanner.pictureUrl} className="img-cover" loading="lazy" alt="" />
              </ScaleBox>
            </HoverTrigger>
          </div>
        )}
        {/* слайдер */}
        {banners.length > 1 && (
          <Swiper
            id="home-swiper"
            options={{
              onSlideChange() {
                setSlideIndex(this.activeIndex)
              },
              autoplay: {
                delay: 4000,
                disableOnInteraction: false
              },
              slidesPerView: "auto",
              spaceBetween: 16,
              cssMode: true
            }}
          >
            {banners.map(banner => (
              <div
                key={banner.id}
                onClick={() => onBannerClick(banner)}
                className={cx("relative h-[160px] md:h-[465px] 2xl:h-[670px]", {
                  "cursor-pointer": Boolean(banner.link)
                })}
              >
                <HoverTrigger className="xh-full w-auto bg-gray-200 dark:bg-black">
                  <ScaleBox className="w-auto">
                    <div className="w-auto h-[160px] md:h-[465px] 2xl:h-[670px] overflow-hidden">
                      <img
                        src={banner.pictureUrl}
                        className="img-cover w-auto h-full scale-[1.1]"
                        alt=""
                      />
                    </div>
                  </ScaleBox>
                </HoverTrigger>
              </div>
            ))}
          </Swiper>
        )}
      </div>
    </>
  )
}

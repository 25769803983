import { DateField, Form, TextField, TextareaField } from "shared/components/form"
import { InviewTrigger, RotateBox } from "shared/components/anime"

import { dateFromToFormat } from "shared/helpers"
import { makeValidation } from "shared/helpers"
import { useMakeProposal } from "../api"

export function ProposalForm({ tenderId, onSubmitSuccess }) {
  const { mutate: makeProposal, isLoading } = useMakeProposal({ tenderId })

  const submitForm = (payload, { setError, control }) => {
    const proposal = {
      ...payload,
      finishDate: dateFromToFormat(payload.finishDate)
    }

    makeProposal(proposal, {
      onSuccess: onSubmitSuccess,
      onError: error => {
        const whitelist = Object.keys(control._fields)
        makeValidation(error, setError, whitelist)
      }
    })
  }

  return (
    <Form onSubmit={submitForm}>
      {({ register, formState }) => (
        <>
          <div className="h1 text-center md:text-left">отклик</div>
          <div className="mt-[20px] md:mt-[40px] md:pr-[calc(10%+10px)]">
            <div className="grid gap-[20px]">
              <TextField
                placeholder="стоимость без НДС"
                errorMessage={formState.errors.cost?.message}
                {...register("cost")}
              />
              <DateField
                placeholder="срок выполнения"
                errorMessage={formState.errors.finishDate?.message}
                {...register("finishDate")}
              />
              <TextareaField
                rows={3}
                label="текст предложения"
                errorMessage={formState.errors.comment?.message}
                {...register("comment")}
              />
            </div>
          </div>
          <div className="mt-[20px] md:mt-[60px] 2xl:mt-[100px] text-right">
            <InviewTrigger>
              <RotateBox>
                <button type="submit" disabled={isLoading} className="btn-b no-underline">
                  Откликнуться
                </button>
              </RotateBox>
            </InviewTrigger>
          </div>
        </>
      )}
    </Form>
  )
}

import { InviewTrigger, RotateBox } from "shared/components/anime"

import { Link } from "react-router-dom"

export function AchievementCard({ id, name, image }) {
  return (
    <div className="grid-cols-10 gap-x-[20px] md:grid">
      <div className="col-span-2" />
      <div className="col-span-1 m-auto w-[50%] md:m-0 md:w-[100%]">
        {image && (
          <div className="aspect-[100/100]">
            <img loading="lazy" src={image} className="block h-full w-full object-cover" alt={name} />
          </div>
        )}
      </div>
      <div className="col-span-2" />
      <div className="col-span-5 mt-[20px] md:mt-[0px]">
        <div className="h2">{name}</div>
        <div className="mt-[20px]">
          <InviewTrigger>
            <RotateBox>
              <Link to={`/achievements/${id}`} className="btn no-underline">
                читать
              </Link>
            </RotateBox>
          </InviewTrigger>
        </div>
      </div>
    </div>
  )
}

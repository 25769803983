import { Dropdown } from "shared/components/form"
import { Navigation } from "../components/navigation"
import { NoData } from "shared/components/async"
import { TenderCard } from "../components/tender-card"
import { addProps } from "shared/helpers"
import { useCallback } from "react"
import { useFilter } from "../store"
import { useSpheres } from "shared/api"
import { useTenders } from "../api"

export const Tenders = addProps(function Tenders({ presets }) {
  const filter = useFilter(state => state.filter)
  const setFilterParam = useFilter(state => state.setFilterParam)

  const resetKey = useCallback(key => () => setFilterParam(key, null), [setFilterParam])
  const filterBy = useCallback(key => val => setFilterParam(key, val), [setFilterParam])

  const { data: spheres } = useSpheres()
  const { data: tenders } = useTenders({
    query: {
      statusId: filter.status?.id,
      sphereId: filter.sphere?.id
    }
  })

  return (
    <div className="container-x container-y">
      <div className="text-center md:pl-[calc(50%+10px)] md:text-left">
        <h1 className="h1">Заказы</h1>
      </div>
      <div className="md:hidden text-center mt-[30px]">
        <Navigation />
      </div>
      <div className="mt-[20px] md:mt-[80px] 2xl:mt-[100px]">
        <div className="grid-cols-10 gap-[40px] md:grid">
          <div className="col-span-2 hidden md:block">
            <Navigation />
          </div>
          <div className="col-span-8">
            <div className="grid md:grid-cols-2 gap-[20px]">
              <div className="relative z-20">
                <Dropdown
                  placeholder="Все заказы"
                  resetPlaceholder="Все заказы"
                  options={presets.statuses}
                  selected={filter.status}
                  onChange={filterBy("status")}
                  onReset={resetKey("status")}
                />
              </div>
              {spheres?.length > 0 && (
                <Dropdown
                  placeholder="Все направления"
                  resetPlaceholder="Все направления"
                  options={spheres}
                  selected={filter.sphere}
                  onChange={filterBy("sphere")}
                  onReset={resetKey("sphere")}
                />
              )}
            </div>
            {tenders && (
              <div className="grid gap-[20px] mt-[40px] md:mt-[80px] 2xl:mt-[200px]">
                {tenders.length === 0 && <NoData />}
                {tenders.map(tender => (
                  <TenderCard
                    id={tender.id}
                    key={tender.id}
                    dateStart={tender.startAt}
                    dateEnd={tender.finishAt}
                    spheres={tender.sphereNames}
                    name={tender.name}
                    respondStatus={tender.userState}
                    href={`/cabinet/tenders/${tender.id}`}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}, require("../mock.json"))

import { THEME_DARK } from "../constants"
import { useEffect } from "react"
import { useThemeStore } from "../store"

export function useApplyThemeStyle() {
  const theme = useThemeStore(state => state.theme)
  const setDarkTheme = useThemeStore(state => state.setDarkTheme)
  const setLightTheme = useThemeStore(state => state.setLightTheme)

  useEffect(() => {
    const method = theme === THEME_DARK ? "add" : "remove"
    document.documentElement.classList[method]("dark")
  }, [theme])

  useEffect(() => {
    if (null === theme) {
      const prefersDark = window?.matchMedia?.("(prefers-color-scheme: dark)").matches
      prefersDark ? setDarkTheme() : setLightTheme()
    }
  }, [theme, setDarkTheme, setLightTheme])

  return { theme }
}

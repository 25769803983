import { useForm } from "react-hook-form"

export function Form({ options, children, onSubmit }) {
  const methods = useForm(options)
  return (
    <form onSubmit={methods.handleSubmit(data => onSubmit(data, methods))}>
      {children(methods)}
    </form>
  )
}

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useProjects, useProject, useSearchPresets }

function getSearchPresets() {
  return http("/projects/presets")
}

function getProject(id) {
  return http(`/projects/${id}`)
}

function getProjects(query = {}) {
  return http("/projects", {
    params: {
      limit: query?.limit
    }
  })
}

function useProjects({ query, config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["projects", query],
    queryFn: () => getProjects(query)
  })
}

function useProject({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["project", id],
    queryFn: () => getProject(id),
    useErrorBoundary: true
  })
}

function useSearchPresets({ config } = {}) {
  return useQuery({
    select: data => data.data,
    ...config,
    queryKey: ["projects-presets"],
    queryFn: getSearchPresets
  })
}

import "assets/styles.css"

import * as interceptors from "shared/http-interceptors"

import { decRequest, incRequest } from "features/misc"
import { logout, useAuthStore } from "features/auth"

import { App } from "./app"
import { addNotification } from "features/misc"
import { createRoot } from "react-dom/client"
import { http } from "shared/http"
import { setLogger } from "react-query"

const el = document.getElementById("root")
createRoot(el).render(<App />)

let log, warn, error
log = warn = error = () => {}
setLogger({ log, warn, error })

const logoutIntercept = interceptors.logout(logout)
const setAuthIntercept = interceptors.setAuth(() => useAuthStore.getState().token)
const errorMessageIntercept = interceptors.errorMessage(message =>
  addNotification({
    type: "error",
    message
  })
)

http.interceptors.response.use(response => response.data)
http.interceptors.response.use(null, logoutIntercept)
http.interceptors.response.use(null, errorMessageIntercept)
http.interceptors.response.use(
  response => {
    decRequest()
    return response
  },
  error => {
    decRequest()
    return Promise.reject(error)
  }
)

http.interceptors.request.use(setAuthIntercept)
http.interceptors.request.use(config => {
  incRequest()
  return config
})

import { http } from "shared/http"
import { useQuery } from "react-query"

export { useEmployees, useEmployee }

function getEmployee(id) {
  return http(`/employees/${id}`)
}

function getEmployees() {
  return http("/employees")
}

function useEmployees({ config } = {}) {
  return useQuery({
    ...config,
    queryKey: ["employees"],
    queryFn: getEmployees
  })
}

function useEmployee({ id, config }) {
  return useQuery({
    ...config,
    queryKey: ["employee", id],
    queryFn: () => getEmployee(id),
    useErrorBoundary: true
  })
}

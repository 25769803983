import { useEffect, useRef, useState } from "react"

import { cx } from "shared/helpers"

export function Cursor({ clickables = CLICKABLES }) {
  const $cursor = useRef()
  const [ready, setReady] = useState(false)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const cursor = $cursor.current

    const mouse = { x: 0, y: 0 }
    const pos = { x: 0, y: 0 }
    const speed = 0.25

    const updateCoordinates = e => {
      mouse.x = e.clientX
      mouse.y = e.clientY
      setReady(true)
    }

    window.addEventListener("mousemove", updateCoordinates)

    const updateCursor = () => {
      const diffX = Math.round(mouse.x - pos.x)
      const diffY = Math.round(mouse.y - pos.y)

      pos.x += diffX * speed
      pos.y += diffY * speed

      const translate = "translate3d(" + pos.x + "px ," + pos.y + "px, 0)"
      cursor.style.transform = translate
    }

    const loop = () => {
      updateCursor()
      requestAnimationFrame(loop)
    }

    requestAnimationFrame(loop)

    return () => window.removeEventListener("mousemove", updateCoordinates)
  }, [])

  useEffect(() => {
    const selector = clickables.join(",")

    const mouseenter = ev => {
      if (ev.target.matches(selector) || ev.target.closest(selector)) {
        ev.target.addEventListener("mouseleave", () => setIsActive(false), { once: true })
        setIsActive(true)
      }
    }

    document.body.addEventListener("mouseover", mouseenter)
    return () => document.body.removeEventListener("mouseover", mouseenter)
  }, [clickables])

  return (
    <div
      className={cx(
        "pointer-events-none fixed -top-[35px] -left-[35px] z-50 hidden h-[70px] w-[70px] opacity-0 mix-blend-difference md:block",
        {
          "opacity-100": ready
        }
      )}
      ref={$cursor}
    >
      <div
        className={cx(
          "h-[70px] w-[70px] rounded-[50%] border-4 border-solid bg-black transition-all duration-500 dark:border-white",
          {
            "scale-50": isActive
          }
        )}
      />
    </div>
  )
}

const CLICKABLES = [
  "a",
  "input[type='text']",
  "input[type='email']",
  "input[type='number']",
  "input[type='submit']",
  "input[type='image']",
  "label[for]",
  "select",
  "textarea",
  "button",
  ".link",
  ".cursor-pointer"
]

export { Container, Layout }

function Container({ children }) {
  return <div className="my-[20px] md:my-[60px] 2xl:my-[100px]">{children}</div>
}

function Layout({ children }) {
  return (
    <div className="md:grid grid-cols-10 gap-[20px]">
      <div className="col-span-5 col-start-6">{children}</div>
    </div>
  )
}

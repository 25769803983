import { forwardRef, useEffect, useState } from "react"

import { ErrorMessage } from "./error-message"
import { PatternFormat } from "react-number-format"
import { cx } from "shared/helpers"

export const PhoneField = forwardRef((props, ref) => {
  const {
    value,
    defaultValue,
    errorMessage,
    onValueChange = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...rest
  } = props

  const [localValue, setLocalValue] = useState()
  const hasValue = !!localValue

  const [hover, setHover] = useState(false)
  const [ready, setReady] = useState(false)

  const handleChange = (values, ev) => {
    setLocalValue(values.value)
    onValueChange(values, ev)
  }

  const handleMouseEnter = ev => {
    setHover(true)
    onMouseEnter(ev)
  }

  const handleMouseLeave = ev => {
    setHover(false)
    onMouseLeave(ev)
  }

  useEffect(() => {
    setLocalValue(value || defaultValue)
    setReady(true)
  }, [value, defaultValue])

  return (
    <div>
      <PatternFormat
        {...rest}
        key={ready}
        getInputRef={ref}
        type="text"
        className={cx(
          "h2 h-[60px] w-full border-2 border-gray-900 px-[20px] outline-none placeholder:text-gray-900 placeholder:opacity-30 hover:bg-gray-900 hover:placeholder:text-white dark:border-white dark:placeholder:text-white dark:hover:bg-white dark:hover:placeholder:text-gray-900 md:h-[90px] md:px-[30px] 2xl:h-[100px]",
          {
            "bg-gray-900": hasValue,
            "dark:bg-white": hasValue,
            "text-white": hasValue,
            "bg-white": !hasValue,
            "dark:bg-transparent": !hasValue,
            "text-white/[.3]": hover && !hasValue,
            "dark:text-gray/[.3]": hover && !hasValue,
            "dark:text-gray-900/[.3]": hover && !hasValue,
            "dark:text-gray-900": hasValue
          }
        )}
        mask="*"
        format="+7 (###) #######"
        allowEmptyFormatting={hover}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        value={value}
        defaultValue={defaultValue}
        onValueChange={handleChange}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
})

import { HoverTrigger, Underline } from "shared/components/anime"

import { ReactComponent as FileIcon } from "../assets/file.svg"

export function Files({ files }) {
  return (
    <div>
      <div className="h3 text-gray-900/50 dark:text-white/50">Файлы</div>
      <div className="grid gap-[20px] mt-[10px]">
        {files.map(file => (
          <HoverTrigger key={file.hash}>
            <a
              href={file.url}
              target="_blank"
              rel="noreferrer"
              className="grid grid-cols-[auto_1fr] items-end"
            >
              <div className="w-[32px] h-[32px] md:w-[38px] md:h-[38px] 2xl:w-[38px] 2xl:h-[38px]">
                <FileIcon className="w-full h-full block" />
              </div>
              <div className="grid pl-[10px] md:pl-[20px]">
                <div className="h2 leading-none whitespace-nowrap overflow-hidden text-ellipsis">
                  <Underline>{file.comment}</Underline>
                </div>
                <div className="grid mt-[5px] leading-none text-xs lowercase text-gray-900/50 dark:text-white/50">
                  <div className="whitespace-nowrap overflow-hidden text-ellipsis">{file.url}</div>
                </div>
              </div>
            </a>
          </HoverTrigger>
        ))}
      </div>
    </div>
  )
}

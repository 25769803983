import { ReactComponent as Next } from "../assets/next.svg"
import { ReactComponent as Prev } from "../assets/prev.svg"
import { useSwiper } from "swiper/react"

export function Controller() {
  const swiper = useSwiper()
  return (
    <>
      <div className="pointer-events-auto absolute left-[20px] top-[50%] z-10 mt-[-50px]">
        <button
          aria-label="Предыдущий"
          className="text-white opacity-30 transition-all duration-500 hover:opacity-100"
          onClick={() => swiper.slidePrev()}
        >
          <Prev className="h-[100px] w-[163px]" />
        </button>
      </div>
      <div className="pointer-events-auto absolute right-[20px] top-[50%] z-10 mt-[-50px]">
        <button
          aria-label="Следующий"
          className="text-white opacity-30 transition-all duration-500 hover:opacity-100"
          onClick={() => swiper.slideNext()}
        >
          <Next className="h-[100px] w-[163px]" />
        </button>
      </div>
    </>
  )
}

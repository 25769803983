import { CookieInformer } from "./cookie-informer"
import { Cursor } from "shared/components/cursor"
import { Footer } from "./footer"
import { Header } from "./header"
import { Outlet } from "react-router-dom"
import { ScrollRestoration } from "react-router-dom"
import { cx } from "shared/helpers"
import { useApplyThemeStyle } from "../hooks/use-apply-theme-style"
import { useState } from "react"

export function Layout() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  useApplyThemeStyle()

  return (
    <>
      <div className="bg:white flex min-h-screen flex-col dark:bg-black">
        <Header onOpen={() => setMenuIsOpen(true)} onClose={() => setMenuIsOpen(false)} />
        <main
          className={cx(
            "flex min-h-[calc(100vh-56px)] max-w-[100vw] flex-1 flex-col text-gray-900 dark:text-white md:min-h-[calc(100vh-110px)] 2xl:min-h-[calc(100vh-125px)]",
            { "opacity-0": menuIsOpen }
          )}
        >
          <Outlet />
        </main>
        <Footer />
      </div>
      <ScrollRestoration />
      <CookieInformer />
      <Cursor />
    </>
  )
}
